import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';

@Injectable()
export class SharedSubjectService {
  searchKeyWord: Subject<string> = new Subject<string>();
  folderRefresh: Subject<string> = new Subject<string>();
  private roleSelectionSubject = new BehaviorSubject<any>(null);
  private groupSelectionSubject = new BehaviorSubject<any>(null);
  private userSelectionSubject = new BehaviorSubject<any>(null);
  private managerSelectionSubject = new BehaviorSubject<any>(null);

  constructor() { }

  setGroupData(data: any) {
    this.groupSelectionSubject.next(data);
  }

  getGroupData(): Observable<any> {
    return this.groupSelectionSubject.asObservable();
  }

  setRoleData(data: any) {
    this.roleSelectionSubject.next(data);
  }

  getRoleData(): Observable<any> {
    return this.roleSelectionSubject.asObservable();
  }

  setUserData(data: any) {
    this.userSelectionSubject.next(data);
  }

  getUserData(): Observable<any> {
    return this.userSelectionSubject.asObservable();
  }

  setManagerData(data: any) {
    this.managerSelectionSubject.next(data);
  }

  getManagerData(): Observable<any> {
    return this.managerSelectionSubject.asObservable();
  }

}