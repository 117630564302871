import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SAMLLogin } from '../shared/models/auth/saml.login.model';
import { CompanyService } from '../shared/services/company.service';
import { Company } from '../shared/models/company.model';
import { AuthService } from '../shared/services/auth.service';
import { GlobalService } from '../shared/services/global.service';
import { ApiResponse } from '../shared/models/api.response';
import { SnackbarService } from '../shared/services/snackBarService.service';
@Component({
  selector: 'app-saml',
  templateUrl: './saml.component.html',
  styleUrls: ['./saml.component.scss']
})
export class SamlComponent implements OnInit {
  samlLogin: SAMLLogin;
  company: Company;
  constructor(private activatedRoute: ActivatedRoute,
    private authService: AuthService,
    private router: Router,
    private snackbarservice: SnackbarService,
    private companyService: CompanyService,
    private globalService: GlobalService) {
    this.samlLogin = new SAMLLogin();
    this.company = new Company();
  }

  ngOnInit(): void {
    this.activatedRoute.queryParams.subscribe(params => {
      
        var accessData = params['accessID'];
        var splitArray = accessData.split('-||-');
        this.samlLogin.token = params['accessToken'];
        let companyName = splitArray[0];
        this.samlLogin.userUuid = splitArray[2];
        this.company.companyName = companyName;
        this.companyService.companyDetail(this.company.companyName).subscribe((res: ApiResponse<Company>) => {
          
          this.company = res.data;
          this.globalService.setCompany('company', JSON.stringify(res.data));
          this.samlLogin.companyId = this.company.id;
          this.samlLogin.accessId = this.company.accessId;
          this.authService.samlLogin(this.samlLogin).subscribe((login: ApiResponse<SAMLLogin>) => {
            if (login) {
              this.globalService.setUser('userData', JSON.stringify(login.data));
              this.snackbarservice.open(login.message,'X' ,'success')
              if (login.data.userType == 'Requestor')
                this.router.navigateByUrl('/access-request/submitted-requests');
              else
                this.router.navigateByUrl('/');
            }
          }, () => {
            // this.router.navigateByUrl('/company?');
            this.router.navigateByUrl(`/company?error=${params['error']}`);
          });
        });
      });
  }
}
