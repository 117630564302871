import { prop, required} from '@rxweb/reactive-form-validators';

export class FactorSetting {
  id: number;
  factorId: number;
  companyId: number;
  factorStatus: boolean;
  @required()
  factorType : string;
  @required()
  passCode: number;
  @required()
  phoneNumber: string;
  userUuid: string;
  ssoStatus:number;
}
