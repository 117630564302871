import { Component, Inject, Input, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-alert-dialog',
  templateUrl: './alert-dialog.component.html',
  styleUrls: ['./alert-dialog.component.scss']
})
export class AlertDialogComponent implements OnInit {

  detail: any;
  buttontype: any;
  delegateAll: boolean = false;
  showLoading = false;
  adHocSync = false;
  createAudit = false;
  auditType: string;
  constructor(@Inject(MAT_DIALOG_DATA) public data: any, private dialogRef: MatDialogRef<AlertDialogComponent>) { }

  ngOnInit(): void {
    this.buttontype = this.data.buttons;
    this.detail = this.data;
    this.auditType = this.data.auditType
    this.adHocSync = this.data?.adHocSync;
    if (this.data.showLoading)
      this.showLoading = this.data.showLoading;
  }

  onClick(action) {
    const data = this.adHocSync ? { action, createAudit: this.createAudit } : action;
    this.dialogRef.close(data);
  }

}
