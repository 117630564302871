import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { RestService } from "./rest.service";
import { ConfigService } from "./utils/config.service";
import { ADSyncSource } from "../models/ad-sync-source.model";
import { ApiResponse } from "../models/api.response";
import { DomainOU } from "../models/company-domain.model";

@Injectable()
export class ADSyncSourceService extends RestService<ADSyncSource> {
    constructor(http: HttpClient, private configService: ConfigService) {
        super('/ad-audits', http, configService);
    }

    public getDomainOus(data) {
        return this.makeRequest("get", `/ad-audits/company-domain-ous`, data);
    }

    public getADDomainOU(data) {
        return this.makeRequest("get", `/ad-audits/ad-domain-ou`, data);
    }

    public getADTaskDetails(data) {
        return this.makeRequest("get", `/ad-audits/ad-task-detail`, data);
    }

    public getADSourceDetails(data) {
        return this.makeRequest("get", `/ad-audits/detail`, data);
    }

    public getSourceDetails(data) {
        return this.makeRequest("get", `/ad-audits/get-source-details`, data);
    }

    public createOrUpdateSource(data) {
        return this.makeRequest(data.sourceId ? 'put' : 'post', data.sourceId ? '/ad-audits/update-source' : '/ad-audits/create-source', data);
    }

    public syncSource(data) {
        return this.makeRequest('post', '/ad-audits/sync-source', data);
    }

    public getInstallerUrl() {
        return this.configService.getApiURl() + '/ad-audits/download-installer';
    }
}