import { Component, Inject, Input, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AuthService } from '../../services/auth.service';
import { GlobalService } from '../../services/global.service';

@Component({
  selector: 'app-idle-modal',
  templateUrl: './idle-modal.component.html',
  styleUrls: ['./idle-modal.component.scss']
})
export class IdleModalComponent implements OnInit {
  idleState: string;
  refreshToken: any;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private authService: AuthService,
    private globalService: GlobalService,
    public dialogRef: MatDialogRef<IdleModalComponent>
  ) { }

  ngOnInit(): void {
    this.idleState = this.data.idleState;
  }

  stay(action) {
    this.dialogRef.close(action);
  }

  logout() {
    this.authService.logout();
    this.dialogRef.close();
  }
}
