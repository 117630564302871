<div fxLayoutAlign="end center">
    <button mat-icon-button mat-dialog-close>
      <mat-icon>close</mat-icon>
    </button>
  </div>
<div mat-dialog-content class="h-80">
    <ngx-extended-pdf-viewer [src]="pdfFileUrl" [zoom]="100" height="600px"></ngx-extended-pdf-viewer>
</div>
<div mat-dialog-actions align="end">
    <div align="end">
        <button type="button" mat-button mat-dialog-close>CANCEL</button>
    </div>
</div>