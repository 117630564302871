import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { CloudProviderState } from 'src/app/shared/enums/cloudprovider-state.enum';
import { SidenavItem } from 'src/app/shared/interfaces/sidenav-item.interface';
import { AuthService } from 'src/app/shared/services/auth.service';
import { GlobalService } from 'src/app/shared/services/global.service';
import { SidenavService } from 'src/app/shared/services/sidenav.service';

@Component({
  selector: 'fury-toolbar-search-bar',
  templateUrl: './toolbar-search-bar.component.html',
  styleUrls: ['./toolbar-search-bar.component.scss']
})
export class ToolbarSearchBarComponent implements OnInit {

  input: string;
  focused: boolean;
  recentlyVisited: SidenavItem[] = [];
  searchList: any[] = [];
  componentList: any[] = [];
  frequentlyVisitedList: any[] = [];
  frequentlyList: any[] = [];
  cloudProvider: string;
  CloudProviderState: any = CloudProviderState;

  constructor(
    private router: Router,
    authService: AuthService,
    private globalService: GlobalService,
    private sidenavService: SidenavService,
  ) {

    this.cloudProvider = this.globalService.user?.cloudProvider || '';
    this.componentList = [
      { link: '/', name: 'Dashboard', isVisible: authService.isInRole(['Admin', 'Reviewer']) },
      { link: '/audit/app/create', name: 'Create Audit', isVisible: authService.isInRole(['Admin']) },
      { link: '/audit/app', name: 'Application Audits', isVisible: (authService.cloudProviderEnabled() && this.cloudProvider != this.CloudProviderState.JumpCloud) },
      { link: '/audit/group', name: 'Group Audits', isVisible: authService.cloudProviderEnabled() },
      { link: '/audit/event', name: 'Event Audits', isVisible: this.cloudProvider == this.CloudProviderState.Okta && authService.cloudProviderEnabled() },
      { link: '/audit/user', name: 'User Audits', isVisible: authService.isInRole(['Admin', 'Reviewer']) },
      { link: '/audit/create/resource', name: 'Create Audits via Resources', isVisible: authService.isInRole(['Admin']) },
      { link: '/audit/resource', name: 'Audits via Resources', isVisible: authService.isInRole(['Admin', 'Reviewer']) },
      { link: '/event-trigger/create', name: 'Create Trigger', isVisible: authService.isInRole(['Admin']) && this.cloudProvider == this.CloudProviderState.Okta },
      { link: '/event-trigger/listTrigger', name: 'Triggers', isVisible: authService.isInRole(['Admin']) && this.cloudProvider == this.CloudProviderState.Okta },
      { link: '/event-trigger/eventTrigger', name: 'Event Trigger Logs', isVisible: authService.isInRole(['Admin']) && this.cloudProvider == this.CloudProviderState.Okta },
      { link: '/access-request/submitted-requests', name: 'My Submitted Requests', isVisible: authService.accessRequest() && this.cloudProvider != this.CloudProviderState.JumpCloud && authService.jipEnabled() && authService.isInRole(['Admin', 'Requestor', 'Reviewer']) },
      { link: '/access-request/approval-queue', name: 'Approval Queue', isVisible: authService.accessRequest() && this.cloudProvider != this.CloudProviderState.JumpCloud && authService.jipEnabled() && authService.isInRole(['Admin', 'Requestor', 'Reviewer']) },
      { link: '/access-request/workflowRequest', name: 'Workflow Request', isVisible: authService.jipEnabled() && authService.isInRole(['Admin']) },
      { link: '/access-request/settings', name: 'Settings', isVisible: authService.isInRole(['Admin']) },
      { link: '/data-source/add', name: 'Add New Source', isVisible: authService.isInRole(['Admin']) },
      { link: '/data-source/', name: 'Data Sources', isVisible: authService.isInRole(['Admin']) },
      { link: '/integrations/api', name: 'API Integrations', isVisible: authService.isInRole(['Admin']) && authService.cloudProviderEnabled() },
      { link: '/integrations/settings', name: 'Integrations Settings', isVisible: authService.isInRole(['Admin']) },
      { link: '/account/change-password', name: 'Change Password', isVisible: authService.isInRole(['Admin']) },
      { link: '/account/account-settings', name: 'Config Settings', isVisible: authService.isInRole(['Admin']) },
      { link: '/account/license-management', name: 'License Management', isVisible: authService.isInRole(['Admin']), },
      { link: '/account/mfa-setting', name: 'Multi-Factor Auth', isVisible: !authService.accessRequest() && authService.isInRole(['Admin']) },
      { link: '/account/email-templates', name: 'Email Templates', isVisible: authService.isInRole(['Admin']) },
      { link: '/identity-trust-score', name: 'Identity Trust Score', isVisible: authService.isInRole(['Admin']) && this.globalService.company.trialStatus == false },
      { link: '/access-request/create', name: 'Create Workflow', isVisible: (authService.isInRole(['Admin']) && this.cloudProvider == this.CloudProviderState.Okta) || (authService.accessRequest() && this.cloudProvider != this.CloudProviderState.JumpCloud) },
      { link: '/access-request/resource-management', name: 'Resource Management', isVisible: authService.isInRole(['Admin']) && authService.accessRequest() && this.cloudProvider != this.CloudProviderState.JumpCloud },
      { link: '/delegation-workflow/create', name: 'Create Delegation WorkFlow', isVisible: authService.isInRole(['Admin']) },
      { link: '/delegation-workflow/delegation-list', name: 'Delegation Workflow', isVisible: authService.isInRole(['Admin']) },
      { link: '/delegation-workflow/enable-delegation', name: 'Delegation WorkFlow settings', isVisible: authService.isInRole(['Admin']) },


    ];

    this.frequentlyList = [

      { link: '/', name: 'Dashboard', isVisible: authService.isInRole(['Admin', 'Reviewer']) },
      { link: '/audit/app/create', name: 'Create Audit', isVisible: authService.isInRole(['Admin']) },
      { link: '/audit/app', name: 'Application Audits', isVisible: (authService.cloudProviderEnabled() && this.cloudProvider != this.CloudProviderState.JumpCloud && authService.isInRole(['Admin', 'Reviewer']) ) },
      { link: '/audit/group', name: 'Group Audits', isVisible: authService.cloudProviderEnabled() && authService.isInRole(['Admin', 'Reviewer']) },
      { link: '/access-request/submitted-requests', name: 'My Submitted Requests', isVisible: authService.accessRequest() && this.cloudProvider != this.CloudProviderState.JumpCloud && this.cloudProvider != this.CloudProviderState.PingOne && authService.jipEnabled() && authService.isInRole(['Admin', 'Requestor', 'Reviewer']) },
      { link: '/access-request/approval-queue', name: 'Approval Queue', isVisible: authService.accessRequest() && this.cloudProvider != this.CloudProviderState.JumpCloud && authService.jipEnabled() && authService.isInRole(['Admin', 'Requestor', 'Reviewer']) },
      { link: '/audit/event', name: 'Event Audits', isVisible: this.cloudProvider == this.CloudProviderState.Okta && authService.cloudProviderEnabled()&& authService.isInRole(['Admin', 'Reviewer'] )},
      { link: '/audit/user', name: 'User Audits', isVisible: authService.isInRole(['Admin', 'Reviewer']) },
      { link: '/audit/resource', name: 'Audits via Resources', isVisible: authService.isInRole(['Admin', 'Reviewer']) },
      { link: '/event-trigger/create', name: 'Create Trigger', isVisible: authService.isInRole(['Admin']) && this.cloudProvider == this.CloudProviderState.Okta},
      { link: '/access-request/settings', name: 'Access Request Settings', isVisible: authService.accessRequest() && this.cloudProvider != this.CloudProviderState.JumpCloud && this.cloudProvider != this.CloudProviderState.PingOne && authService.isInRole(['Admin']) },
      { link: '/data-source/add', name: 'Add New Source', isVisible: authService.isInRole(['Admin']) },
      { link: '/integrations/api', name: 'API Integrations', isVisible: authService.isInRole(['Admin']) && authService.cloudProviderEnabled() },
      { link: '/account/account-settings', name: 'Config Settings', isVisible: authService.isInRole(['Admin']) },
      { link: '/account/change-password', name: 'Change Password', isVisible: authService.isInRole(['Admin']) },
    ];

  }

  ngOnInit() {




    // this.componentList.forEach((item) => {
    //   item.isVisible == true ? this.searchList.push(item) : '';
    // });

    this.searchList = this.componentList.filter((item => item.isVisible));

    this.frequentlyVisitedList = this.frequentlyList.filter((item => item.isVisible));



    this.setupDemoData();

    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {

        const item = this.sidenavService.getItemByRoute(event.urlAfterRedirects);

        if (item) {
          const index = this.recentlyVisited.indexOf(item);
          if (index > -1) {
            this.recentlyVisited.splice(index, 1);
          }

          this.recentlyVisited.unshift(item);

          if (this.recentlyVisited.length > 5) {
            this.recentlyVisited.pop();
          }
        }
      }

    });
  }

  setupDemoData() {
    const formWizard = this.sidenavService.getItemByRoute('/forms/form-wizard');
    if (formWizard) this.recentlyVisited.push(formWizard);

    const inbox = this.sidenavService.getItemByRoute('/apps/inbox');
    if (inbox) this.recentlyVisited.push(inbox);

    const allInOneTable = this.sidenavService.getItemByRoute('/tables/all-in-one-table');
    if (allInOneTable) this.recentlyVisited.push(allInOneTable);

    const editor = this.sidenavService.getItemByRoute('/editor');
    if (editor) this.recentlyVisited.push(editor);

    const googleMaps = this.sidenavService.getItemByRoute('/maps/google-maps');
    if (googleMaps) this.recentlyVisited.push(googleMaps);
  }

  openDropdown() {
    this.focused = true;
  }

  closeDropdown() {
    this.focused = false;
  }

  onKeyup(event: any) {

    if ((event.keyCode === 32 || event.keyCode === 8) && event.target.value.toString().trim().length <= 0) {
      this.searchList = [];
      return;
    }
    this.searchList = this.componentList.filter((item => item.isVisible && item.name.toLowerCase().includes(event.target.value.toString().trim().toLowerCase())));

  }
}
