import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginComponent } from './auth/login/login.component';
import { OktaComponent } from './auth/okta/okta.component';
import { LayoutComponent } from './layout/layout.component';
import { SamlComponent } from './saml/saml.component';
import { PageNotFoundComponent } from './shared/components/page-not-found/page-not-found.component';
import { AuthGuard } from './shared/services/auth-guard.service';

const routes: Routes = [
  { path: 'auth', loadChildren: () => import('./auth/auth.module').then(m => m.AuthModule) },
  { path: 'company', loadChildren: () => import('./company/company.module').then(m => m.CompanyModule) },
  { path: 'okta', component: OktaComponent },
  { path: 'saml', component: SamlComponent },
  { path: 'login', component: LoginComponent },
  {
    path: '', component: LayoutComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: '',
        loadChildren: () => import('./dashboard/dashboard.module').then(m => m.DashboardModule),
        pathMatch: 'full'
      },
      { path: 'sod-rules', loadChildren: () => import('./sod-rules/sod-rules.module').then(m => m.SodRulesModule) },
      { path: 'access-request', loadChildren: () => import('./access-request/access-request.module').then(m => m.AccessRequestModule) },
      { path: 'integrations', loadChildren: () => import('./api-integrations/api-integrations.module').then(m => m.ApiIntegrationsModule) },
      { path: 'data-source', loadChildren: () => import('./data-source/data-source.module').then(m => m.DataSourceModule) },
      { path: 'event-trigger', loadChildren: () => import('./event-triggers/event-triggers.module').then(m => m.EventTriggersModule) },
      { path: 'account', loadChildren: () => import('./my-account/my-account.module').then(m => m.MyAccountModule) },
      { path: 'task', loadChildren: () => import('./task/task.module').then(m => m.TaskModule) },
      { path: 'audit', loadChildren: () => import('./audit/audit.module').then(m => m.AuditModule) },
      { path: 'archived-audits', loadChildren: () => import('./folder/folder.module').then(m => m.FolderModule) },
      { path: 'identity-trust-score', loadChildren: () => import('./identity-trust-score/identity-trust-score.module').then(m => m.IdentityTrustScoreModule) },
      { path: 'attestation', loadChildren: () => import('./folder/folder.module').then(m => m.FolderModule) },
      { path: 'ad-sync', loadChildren: () => import('./ad-sync/ad-sync.module').then(m => m.AdSyncModule) },
      { path: 'delegation-workflow', loadChildren: () => import('./delegation-workflow/delegation-workflow.module').then(m => m.DelegationWorkflowModule) },
      { path: 'settings',loadChildren:()  =>import('./settings/settings.module').then(m =>m.SettingsModule)}
    ]
  },
  { path: '**', component: PageNotFoundComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    anchorScrolling: 'enabled',
    // useHash: true,
    // preloadingStrategy: PreloadAllModules,
    scrollPositionRestoration: 'enabled',
    onSameUrlNavigation: 'reload',
    initialNavigation: 'enabledBlocking'
  })
  ],
  exports: [RouterModule],
})
export class AppRoutingModule { }