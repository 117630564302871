import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { FooterModule } from './footer/footer.module';
import { LayoutComponent } from './layout.component';
import { SidenavModule } from './sidenav/sidenav.module';
import { ToolbarModule } from './toolbar/toolbar.module';
import { ConfigPanelModule } from './config-panel/config-panel.module';
import { NavigationModule } from './navigation/navigation.module';
import { SharedModule } from '../shared/shared.module';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { BackdropModule } from '../shared/modules/backdrop/backdrop.module';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    SharedModule,
    // Core
    ToolbarModule,
    BackdropModule,
    SidenavModule,
    FooterModule,
    ConfigPanelModule,
    NavigationModule,
    MatSidenavModule,
    MatProgressSpinnerModule
  ],
  declarations: [LayoutComponent]
})
export class LayoutModule {
}
