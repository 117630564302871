import { AfterViewInit, Component, ElementRef, EventEmitter, Input, Output, ViewChild, ViewEncapsulation } from '@angular/core';
import { fromEvent } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { ListColumn } from '../list/list-column.model';
import { SharedSubjectService } from '../../services';
@Component({
  selector: 'fury-list-extended',
  templateUrl: './list-extended.component.html',
  styleUrls: ['./list-extended.component.scss']
})
export class ListExtendedComponent implements AfterViewInit {

  @Input() name: string = '';
  @Input() columns: ListColumn[];
  @ViewChild('filter') filter: ElementRef;
  @Output() filterChange = new EventEmitter<string>();
  @Input() searchPlaceholder: string = 'Search here...';
  @Input() hideHeader: boolean;
  @Input() searchText: string = '';
  

  constructor(private sharedSubjectService: SharedSubjectService) {
    if (this.searchText != null) {
      this.sharedSubjectService.searchKeyWord.subscribe(keyword => {
        if (this.filter?.nativeElement?.value) {
          this.filter.nativeElement.value = '';
        }
        
        this.searchText = keyword;
      })
    }
  }

  ngAfterViewInit() {
    if (!this.hideHeader) {
      fromEvent(this.filter.nativeElement, 'keyup').pipe(
        distinctUntilChanged(),
        debounceTime(150)
      ).subscribe(() => {
        
        this.filterChange.emit(this.filter.nativeElement.value || '');
        // this.filter.nativeElement.value = '';
      });

    }
  }

  // inputText(event) {
  //   
  //   event.target.value = '';
  //   this.searchText = '';
  // }

  toggleColumnVisibility(column, event) {
    event.stopPropagation();
    event.stopImmediatePropagation();
    column.visible = !column.visible;
  }
}
