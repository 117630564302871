import { Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { RxFormBuilder } from '@rxweb/reactive-form-validators';
import { FactorSetting } from 'src/app/shared/models/auth/factor-setting.modal';
import { User } from 'src/app/shared/models/auth/user.model';
import { Company } from 'src/app/shared/models/company.model';
import { fadeInUpAnimation } from '../../shared/animations/fade-in-up.animation';
import { CompanyService } from 'src/app/shared/services/company.service';
import { GlobalService } from 'src/app/shared/services/global.service';
import { AuthService } from 'src/app/shared/services/auth.service';
import { ApiResponse } from 'src/app/shared/models/api.response';
import { environment } from 'src/environments/environment';
import { SnackbarService } from 'src/app/shared/services/snackBarService.service';
import { isPlatformBrowser } from '@angular/common';
import { CloudProviderState } from 'src/app/shared/enums/cloudprovider-state.enum';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  animations: [fadeInUpAnimation]
})
export class LoginComponent implements OnInit {
  copyrightYear = new Date().getFullYear();
  loginForm: FormGroup;
  submitted: boolean = false;
  user: User;
  userRef: User;
  company: Company;
  isSubmitted: boolean = false;
  isPhone: boolean = false;
  isPass: boolean = false;
  isNew: boolean = false;
  isActivate: boolean = false;
  isLoading: boolean = true;
  factor: FactorSetting;
  factorForm: FormGroup;
  otpSent: boolean = false;
  display: any;
  isButtonDisabled: boolean = true;
  hide: boolean = true;
  hideOtp: boolean = true;
  returnUrl: string;
  CloudProviderState: any = CloudProviderState;

  constructor(
    private formBuilder: RxFormBuilder,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private companyService: CompanyService,
    private globalService: GlobalService,
    private authService: AuthService,
    @Inject(PLATFORM_ID) private platformId: Object,
    private snackbarService: SnackbarService
  ) {
    this.factor = new FactorSetting();
    this.user = new User();
    this.userRef = new User();
    this.company = new Company();
  }

  ngOnInit(): void {

    this.loginForm = this.formBuilder.formGroup(User);
    this.factorForm = this.formBuilder.formGroup(FactorSetting);
    if (!environment.production) {
      this.company.companyName = 'oktastage';
      this.companyService.companyDetail(this.company.companyName).subscribe((res: ApiResponse<Company>) => {
        this.company = res.data;
        this.globalService.setCompany('company', JSON.stringify(this.company));
      });
      this.isLoading = false;
      return;
    }

    if (isPlatformBrowser(this.platformId)) {
      let companyName = window.location.origin.split('.');
      this.company.companyName = companyName[0].split(':')[1].replace('//', '');
      this.returnUrl = this.activatedRoute.snapshot.queryParamMap.get('returnUrl') || '';
      if (this.company.companyName == 'console') {
        this.router.navigateByUrl('/company');
      } else {
        this.companyService.companyDetail(this.company.companyName).subscribe((res: ApiResponse<Company>) => {
          this.company = res.data;
          this.globalService.setCompany('company', JSON.stringify(this.company));

          if (this.authService.isAuthenticated()) {
            this.isLoading = false;
            if (this.globalService.user.factorStatus == 'Pending') {
              this.isNew = true;
            } else {
              this.router.navigateByUrl('/dashboard');
            }
        } else {
            if (this.company.ssoEnable && this.company.companyName != 'console') {
              if (this.company.ssoType == 'saml') {
                if (this.company.cloudProvider == this.CloudProviderState.Okta) {
                  window.location.href = environment.samlLoginUrl + this.company.entityId;
                } else if (this.company.cloudProvider == this.CloudProviderState.JumpCloud) {
                  window.location.href = environment.jumpCloudUrl + this.company.accessId;
                } else if (this.company.cloudProvider == this.CloudProviderState.PingOne) {
                  window.location.href = environment.pingOneSamlUrl + this.company.accessId;
                } else {
                  window.location.href = environment.samlAzureUrl + this.company.accessId;
                }
              } else {
                let redirectUrl = environment.ssoRedirectUrl;
                redirectUrl = redirectUrl.replace(
                  redirectUrl.split('//')[1].split('.')[0],
                  this.company.companyName
                );
                var loginUrl =
                  this.company.url +
                  '/oauth2/default/v1/authorize?scope=openid+email+profile&redirect_uri=' +
                  redirectUrl +
                  '&response_type=token id_token&client_id=' +
                  this.company.clientId +
                  '&nonce=' +
                  Math.random().toString(13).replace('0.', '') +
                  '&state=eyJjc3JmX3Rva2VuIjogIkNhZG1';
                window.location.href = loginUrl;
              }
            } else {
              if (this.company.companyName == 'console') {
                this.router.navigateByUrl('/company');
              }
              this.isLoading = false;
              this.activatedRoute.queryParams.subscribe((params: any) => {
                if (params.email) {
                  this.user.name = params.email;
                  this.checkUserType();
                }
              });

            }
          }
        },
          () => {
            setTimeout(() => {
              window.location.href = environment.errorRedirect;
            }, 500);
          }
        );
      }
    }
  }

  userLogin() {
    
    this.isSubmitted = true;
    var firstName = this.loginForm.get('firstName') as FormControl;
    firstName.clearValidators();
    firstName.updateValueAndValidity();
    var lastName = this.loginForm.get('lastName') as FormControl;
    lastName.clearValidators();
    lastName.updateValueAndValidity();
    var userType = this.loginForm.get('userType') as FormControl;
    userType.clearValidators();
    userType.updateValueAndValidity();

    if (this.loginForm.invalid) {
      return;
    }

    this.user.accessId = this.company?.accessId;
    this.user.companyId = this.company.id;
    this.authService.login(this.user).subscribe((res: ApiResponse<User>) => {

      if (Object.entries(res.data).length > 0) {
        this.userRef = res.data;
        this.globalService.user = this.userRef;
        if (this.globalService.user.factorType) {
          this.userRef.factorStatus = 'Pending';
        }
        if (this.globalService.user.factorType) {
          if (this.globalService.user.factorId) {
            this.isActivate = true;
            this.isNew = true;

            this.factor.factorType = this.globalService.user.factorType;
            this.factor.factorId = this.globalService.user.factorId;
            this.factor.userUuid = this.globalService.user.uuid;
            this.factor.phoneNumber =
              this.globalService.user.factorPhone?.replace(/.(?=.{4})/g, '*');
            this.authService
              .factorChallenege(this.factor)
              .subscribe((res: any) => {
                this.isActivate = true;
              });
          } else if (this.globalService.user.factorPhone) {
            this.factor.phoneNumber = this.globalService.user.factorPhone;
            this.addFactor();
          } else {
            this.isNew = true;
          }
        } else {
          this.snackbarService.open(res.message, 'Close', 'success');
          this.globalService.setUser('userData', JSON.stringify(this.userRef));
          if (this.userRef.userType == 'Requestor')
            this.router.navigateByUrl('/access-request/submitted-requests');
          else this.router.navigateByUrl(this.returnUrl);
        }
      }
    });
  }

  checkUserType() {

    if (this.loginForm.get('email').invalid) return;
    this.authService.checkUserType({ companyId: this.globalService.company.id, email: this.user.email })
      .subscribe((res: any) => {
        this.user.userType = res.data;
        this.otpSent = true;
        this.isButtonDisabled = true;
        if (this.user.userType == 'Reviewer') {
          this.timer();
        }
      });
  }

  addFactor() {
    this.isPhone = true;
    const factorType = this.factorForm.controls['factorType'] as FormControl;
    factorType.clearValidators();
    factorType.updateValueAndValidity();
    const passCode = this.factorForm.controls['passCode'] as FormControl;
    passCode.clearValidators();
    passCode.updateValueAndValidity();
    this.factor.userUuid = this.globalService.user.uuid;
    this.factor.factorType = this.globalService.user.factorType;
    this.factor.userUuid = this.globalService.user.uuid,
      this.factor.companyId = this.globalService.company.id;
    if (this.factorForm.invalid) return;
    if (this.factor.phoneNumber.substring(0, 2) != '+1') {
      this.factor.phoneNumber = `+1${this.factor.phoneNumber}`;
    }

    this.authService.enrollFactor(this.factor).subscribe((res: any) => {
      this.isActivate = true;
      this.factor.factorId = res.data.id;
      passCode.setValidators([Validators.required]);
      passCode.updateValueAndValidity();
    });
  }

  activateFactor() {
    this.isPass = true;
    const factorType = this.factorForm.controls['factorType'] as FormControl;
    factorType.clearValidators();
    factorType.updateValueAndValidity();
    const phoneNumber = this.factorForm.controls['phoneNumber'] as FormControl;
    phoneNumber.clearValidators();
    phoneNumber.updateValueAndValidity();
    this.factor.phoneNumber = this.globalService.user.factorType;
    if (this.factorForm.invalid) return;
    if (this.globalService.user.factorId) {
      this.authService.verifyFactor(this.factor).subscribe((res: any) => {
        
        if (res.data.factorResult == 'SUCCESS') {
          var user = this.globalService.user;
          user.factorStatus = 'ACTIVE';
          this.globalService.setUser('userData', JSON.stringify(user));
          this.loginRedirect();
        }
      });
    } else {
      this.authService.activateFactor(this.factor).subscribe((res: any) => {
        if (res.data.status == 'ACTIVE') {
          var user = this.globalService.user;
          user.factorStatus == res.status;
          this.globalService.setUser('userData', JSON.stringify(user));
          this.loginRedirect();
        }
      });
    }
  }

  loginRedirect() {
    this.snackbarService.open('Logged in successfully.', 'Close', 'success');
    if (this.user.userType == 'Requestor')
      this.router.navigateByUrl('/access-request/submitted-requests');
    else this.router.navigateByUrl('/');
  }

  timer() {
    var timeleft = 60;
    var timer = setInterval(() => {
      timeleft--;
      if (timeleft <= 0) {
        this.isButtonDisabled = false;
        clearInterval(timer);
        document.getElementById("countdown").innerHTML = "00:00";
      } else {
        document.getElementById("countdown").innerHTML = "00:" + timeleft.toString().padStart(2, "0");
      }
    }, 1000);
  }

}
