import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { RestService } from '../rest.service';
import { ConfigService } from '../utils/config.service';
import { ResourceManagment } from '../../models/access-request/resource-management.model';

@Injectable({ providedIn: 'root' })
export class ResourceManagmentService extends RestService<ResourceManagment> {

  constructor(configService: ConfigService, http: HttpClient) {
    super('/access-requests/resource-management', http, configService);
  }
}