import { Component, EventEmitter, HostBinding, HostListener, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { SidenavItem } from 'src/app/shared/interfaces/sidenav-item.interface';
import { SidenavService } from 'src/app/shared/services/sidenav.service';
import { ThemeService } from 'src/app/shared/services/theme.service';

@Component({
  selector: 'fury-sidenav',
  templateUrl: './sidenav.component.html',
  styleUrls: ['./sidenav.component.scss']
})
export class SidenavComponent implements OnInit, OnDestroy {

  sidenavUserVisible$ = this.themeService.config$.pipe(map(config => config.sidenavUserVisible));
  @Input()
  @HostBinding('class.collapsed')
  collapsed: boolean;
  @Input()
  @HostBinding('class.expanded')
  expanded: boolean;
  items$: Observable<SidenavItem[]>;
  @Output() messageEvent = new EventEmitter<any>();
  constructor(private router: Router,
    private sidenavService: SidenavService,
    private themeService: ThemeService) {
  }

  ngOnInit() {
    this.items$ = this.sidenavService.items$.pipe(
      map((items: SidenavItem[]) => this.sidenavService.sortRecursive(items, 'position'))
    );
  }
  receiveMessage($event) {
    // alert('ok')
    this.messageEvent.emit(true)
  }
  toggleCollapsed() {
    this.sidenavService.toggleCollapsed();
  }
  @HostListener('mouseenter')
  @HostListener('touchenter')
  onMouseEnter() {
    this.sidenavService.setExpanded(true);
  }
  @HostListener('mouseleave')
  @HostListener('touchleave')
  onMouseLeave() {
    this.sidenavService.setExpanded(false);
  }

  ngOnDestroy() {
  }
}
