import { User } from '../models/auth/user.model';
import { CookieService } from 'ngx-cookie-service';
import { Injectable } from '@angular/core';
import { Company } from '../models/company.model';
import { ChartData } from 'chart.js';
import { of } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({ providedIn: "root" })
export class GlobalService {
  company: Company;
  user: User;

  private constructor(private cookieService: CookieService) {
    this.user = JSON.parse(this.cookieService.get("userData") == '' ? null : this.cookieService.get("userData"));
    this.company = JSON.parse(this.cookieService.get("company") == '' ? null : this.cookieService.get("company"));
  }

  public setCompany(key, value) {
    this.cookieService.set(key, value, {
      path: '/',
    });

    this.company = JSON.parse(this.cookieService.get("company"));
  }

  public setUser(key, value) {
    this.cookieService.set(key, value, {
      path: '/',
    });

    this.user = JSON.parse(this.cookieService.get("userData"));
  }

  public removeCompany(key) {
    this.cookieService.delete(key, '/');
    this.company = null;
  }

  public removeUser(key) {
    this.cookieService.delete(key, '/');
    this.user = null;
  }

  public setItem(key, value) {
    this.cookieService.set(key, value);
  }

  public getItem(key) {
    if (this.cookieService.get(key) == '') {
      return null;
    } else {
      return this.cookieService.get(key);
    }

  }
  public removeItem(key) {
    this.cookieService.delete(key)
  }
  public clear() {
    this.cookieService.deleteAll();
  }

  getChartData(data, forLicenses = false) {
    // Simulating request from local data
    return of(data).pipe(
      map(values => this.prepareChartData(values, forLicenses))
    );
  }

  prepareChartData(chartData: { label: string, value: number }[], forLicenses) {
    return {
      labels: chartData.map(data => data.label),
      datasets: [
        {
          data: chartData.map(data => data.value),
          // backgroundColor: ['#0D98BA', '#607D88']
           backgroundColor: forLicenses ? ['#607D88', '#4CAF50'] : ['#4CAF50', '#607D88'] 
          // backgroundColor: ['#4b6674', '#b3d7e8']

        }
      ]
    } as ChartData;
  }
}

