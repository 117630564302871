export enum AuditType {
    App = 'app',
    Group = 'group',
    Event = 'event',
    User = 'user',
    Resource = 'resource'
}

export function getAuditTypeFromString(value: string): AuditType | undefined {
    const auditTypes = Object.keys(AuditType).filter(key => typeof AuditType[key as any] === 'string');
    const matchedKey = auditTypes.find(key => AuditType[key as any].toLowerCase() === value.toLowerCase());

    return matchedKey ? AuditType[matchedKey as any] : undefined;
}
