import { SharedSubjectService } from './../../shared/services/shared-subject.service';
import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { RxFormBuilder } from '@rxweb/reactive-form-validators';
import { fadeInRightAnimation } from 'src/app/shared/animations/fade-in-right.animation';
import { fadeInUpAnimation } from 'src/app/shared/animations/fade-in-up.animation';
import { ApiResponse } from 'src/app/shared/models/api.response';
import { ArchivedFolder } from 'src/app/shared/models/folder/create-folder.model';
import { FolderService } from 'src/app/shared/services/folder.service';
import { GlobalService } from 'src/app/shared/services/global.service';
import { SnackbarService } from 'src/app/shared/services/snackBarService.service';
import { FlatTreeControl } from '@angular/cdk/tree';
import { MatTreeFlatDataSource, MatTreeFlattener } from '@angular/material/tree';
import { eNavItemType } from 'src/app/shared/interfaces/sidenav-item.interface';
import { DocumentFolderService } from 'src/app/shared/services/document-folder.service';
interface ArchiveFolderList {
  id: string;
  name: string;
  subItems?: ArchiveFolderList[];
  url: string;
}
interface ExampleFlatNode {
  expandable: boolean;
  name: string;
  level: number;
}
@Component({
  selector: 'app-create-folder',
  templateUrl: './create-folder.component.html',
  styleUrls: ['./create-folder.component.scss'],
  animations: [fadeInRightAnimation, fadeInUpAnimation]
})

export class CreateFolderComponent implements OnInit {
  archivedFolder: ArchivedFolder;
  archiveFolders: ArchivedFolder[];
  folderForm: FormGroup;
  itemType: eNavItemType;
  eNavItemType = eNavItemType;
  submitted: boolean = false;
  pageType: any;
  folderId: any;
  nodeSelected: boolean = true;
  private _transformer = (node: ArchiveFolderList, level: number) => {
    return {
      expandable: true,
      name: node.name,
      level: level,
      url: node.url,
      id: node.id,
    };
  }

  treeControl = new FlatTreeControl<ExampleFlatNode>(
    node => node.level, node => node.expandable);

  treeFlattener = new MatTreeFlattener(
    this._transformer, node => node.level, node => node.expandable, node => node.subItems);

  dataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);

  constructor(formBuilder: RxFormBuilder,
    private snackbarService: SnackbarService,
    private folderService: FolderService,
    private documentFolderService: DocumentFolderService,
    private dialogRef: MatDialogRef<CreateFolderComponent>,
    private globalService: GlobalService,
    private sharedSubjectService: SharedSubjectService,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
    this.archivedFolder = new ArchivedFolder();
    this.archivedFolder.companyId = this.globalService.company.id;
    this.folderForm = formBuilder.formGroup(this.archivedFolder);
  }

  ngOnInit(): void {
    this.pageType = this.data.pageType;
    this.itemType = this.data.itemType;
    if (this.pageType == 'edit') {
      this.archivedFolder.label = this.data.folderName;
      this.archivedFolder.folderId = this.data.folderId;
    }
    this.getArchiveFolder();
    this.treeControl.expandAll();
  }

  selectParent(id, name) {
    this.nodeSelected = false;
    this.archivedFolder.parentFolderId = id;
    this.archivedFolder.name = name;

    setTimeout(() => {
      this.nodeSelected = true;
    }, 2000);
  }

  getArchiveFolder() {
    const req = {
      companyId: this.globalService.company.id,
      offset: 0,
      limit: 50,
    };

    if (this.itemType === eNavItemType.archives) {
      this.folderService.getArchivedFolder(req).subscribe((res: ApiResponse<any[]>) => {
        this.archiveFolders = res.data;
        this.dataSource.data = res.data;
        setTimeout(() => {
          this.expandAll();
        }, 0);
      });
    } else {
      this.documentFolderService.getPdfDocumentFolders(req).subscribe((res: ApiResponse<any[]>) => {
        this.archiveFolders = res.data;
        this.dataSource.data = res.data;
        setTimeout(() => {
          this.expandAll();
        }, 0);
      });
    }
  }

  expandAll() {
    this.treeControl.dataNodes.forEach(node => {
      if (this.treeControl.isExpandable(node)) {
        this.treeControl.expand(node);
      }
    });
  }


  filterChanged(value: string) {
    this.archivedFolder.parentFolderId = value;
  }

  hasChild = (_: number, node: ExampleFlatNode) => node.expandable;

  onClick(action) {
    this.dialogRef.close(action);
  }

  createFolder() {
    this.submitted = true;
    this.folderForm.markAllAsTouched();
  
    if (!this.archivedFolder.isChecked) {
      this.folderForm.get('name').clearValidators();
    } else {
      this.folderForm.get('name').setValidators([Validators.required]);
    }
  
    this.folderForm.get('name').updateValueAndValidity();
  
    if (this.folderForm.invalid) return;
  
    const request = {
      folderId: this.archivedFolder.folderId,
      parentFolderId: this.archivedFolder.parentFolderId,
      label: this.archivedFolder.label,
    };
  
    const folderService = this.itemType === eNavItemType.archives
      ? this.folderService
      : this.documentFolderService;
  
    const action = this.pageType === 'edit' ? folderService.updateFolders(request) : folderService.createFolders(this.archivedFolder);
  
    action.subscribe(res => {
      this.snackbarService.open(res.message, 'Close', 'success');
      this.sharedSubjectService.folderRefresh.next('');
      this.dialogRef.close(res);
    });
  }
}
