<div fxLayoutAlign="end">
  <button align="end" type="button" class="close" aria-label="Close" mat-button mat-dialog-close>
    <mat-icon>close</mat-icon>
  </button>
</div>
<h3 fxLayoutAlign="center" mat-dialog-title>You Have Been Idle for 15 mins!</h3>
<div mat-dialog-content>
  {{ data.idleState }}
</div>
<div mat-dialog-actions fxLayout="row" fxLayoutAlign="end center">
  <button mat-raised-button color="warn" mat-dialog-close (click)="logout()">Logout</button>
  <button mat-raised-button (click)="stay(true)">Stay</button>
</div>