import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import { Component, Inject, OnInit, PLATFORM_ID, Renderer2, AfterViewInit } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { filter } from 'rxjs';
import { Platform } from '@angular/cdk/platform';
import { SidenavService } from './shared/services/sidenav.service';
import { SplashScreenService } from './shared/services/splash-screen.service';
import { ThemeService } from './shared/services/theme.service';
import { ReactiveFormConfig } from '@rxweb/reactive-form-validators';
import { DEFAULT_INTERRUPTSOURCES, Idle } from '@ng-idle/core';
import { GlobalService } from './shared/services/global.service';
import { AuthService } from './shared/services/auth.service';
import { Keepalive } from '@ng-idle/keepalive';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { IdleModalComponent } from './shared/components/idle-modal/idle-modal.component';
import { ApiResponse } from './shared/models/api.response';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, AfterViewInit {
  private dialogRef: MatDialogRef<IdleModalComponent> | any;

  idleState = 'Not started.';
  timedOut = false;
  lastPing?: Date = null;
  data: any;

  constructor(private sidenavService: SidenavService,
    private idle: Idle,
    private globalService: GlobalService,
    private keepalive: Keepalive,
    public dialog: MatDialog,
    private authService: AuthService,
    private iconRegistry: MatIconRegistry,
    private renderer: Renderer2,
    private router: Router,
    private themeService: ThemeService,
    @Inject(DOCUMENT) private document: Document,
    private platform: Platform,
    @Inject(PLATFORM_ID) private platformId: Object,
    private route: ActivatedRoute,
    private splashScreenService: SplashScreenService) {
    if (this.globalService.user) {
      // sets an idle timeout of 5 seconds, for testing purposes.
      idle.setIdle(50 * 60);
      // sets a timeout period of 5 seconds. after 10 seconds of inactivity, the user will be considered timed out.
      idle.setTimeout(30);
      idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);

      idle.onIdleEnd.subscribe(() => {
        this.idleState = '';
        this.idleState = 'No longer idle!';
        if (this.data)
          setTimeout(() => this.data.close(), 5000);
      });

      idle.onTimeout.subscribe(() => {
        this.idleState = 'Timed out!';
        // this.data.idleState = this.idleState;
        setTimeout(() => this.data.close(), 2000);
        this.timedOut = true;
        this.authService.logout();
      });

      idle.onIdleStart.subscribe(() => {
        this.idleState = 'You\'ve gone idle!'
        this.dialogRef = this.dialog.open(IdleModalComponent, {
          width: '28vw',
          maxHeight: '100vh',
          data: {
            idleState: this.idleState
          }
        });

        this.dialogRef.afterClosed().subscribe((res) => {
          if (res == true) {
            this.authService.getRefreshToken({ refreshToken: this.globalService.user.refreshToken }).subscribe((res: ApiResponse<any>) => {
              var user = this.globalService.user;
              this.globalService.setUser('userData', JSON.stringify(user));
            });
          }
        });
      });

      idle.onTimeoutWarning.subscribe((countdown) => {
        this.idleState = 'You will time out in ' + countdown + ' seconds!';
        
        if (this.dialogRef && this.dialogRef.componentInstance) {
          this.dialogRef.componentInstance.data = { idleState: this.idleState };
        }
      });

      keepalive.interval(15);

      keepalive.onPing.subscribe(() => this.lastPing = new Date());

      this.reset();
    }

    this.route.queryParamMap.pipe(
      filter(queryParamMap => queryParamMap.has('style'))
    ).subscribe(queryParamMap => this.themeService.setStyle(queryParamMap.get('style')));

    this.iconRegistry.setDefaultFontSetClass('material-icons-outlined');
    this.themeService.theme$.subscribe(theme => {
      if (theme[0]) {
        this.renderer.removeClass(this.document.body, theme[0]);
      }

      this.renderer.addClass(this.document.body, theme[1]);
    });

    if (this.platform.BLINK) {
      this.renderer.addClass(this.document.body, 'is-blink');
    }
  }
 

  ngOnInit() {
    ReactiveFormConfig.set({
      validationMessage: {
        required: 'This field is required.',
        requiredTrue: 'Please check the checkbox.',
        minLength: 'Enter minimum length of 8 characters.',
        maxLength: 'Enter maximum length of 50 characters.',
        compare: 'The value should be same as given in Password field',
        alpha: 'You can only enter alphabets.',
        email: 'Invalid email format',
        requireCheckbox: 'Atleast one required.',
        maxNumber: 'must be between',
        minNumber: 'must be between',
        extension: 'Invalid file format',
        alphaNumeric: 'Only alpha numeric values are allowed.',
        notEmpty: 'This field is required.',
        minDate: "Date value must be either today's or greater",
      },
      "internationalization":
      {
        "dateFormat": "dmy",
        "seperator": "/"
      }
    });
  }

  ngAfterViewInit() {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        setTimeout(() => {
          if (isPlatformBrowser(this.platformId)) {
            window.scrollTo(0, 0);
          }
        });
      }
    });
  }
  
  reset() {
    this.idle.watch();
    this.idleState = 'Started.';
    this.timedOut = false;
  }
}
