import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-doc-viewer',
  templateUrl: './doc-viewer.component.html',
  styleUrls: ['./doc-viewer.component.scss']
})
export class DocViewerComponent implements OnInit {
  safeDocUrl: SafeResourceUrl | undefined;
  private docUrl: string;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { docUrl: string },
    private dialogRef: MatDialogRef<DocViewerComponent>,
    private sanitizer: DomSanitizer,
    private http: HttpClient
  ) {
    this.docUrl = data.docUrl;
  }

  ngOnInit(): void {
    this.fetchDocument(this.docUrl);
  }

  close(): void {
    this.dialogRef.close();
  }

  private fetchDocument(url: string): void {
    this.http.get(url, { responseType: 'blob', observe: 'response' }).subscribe(response => {
      const contentType = response.headers.get('Content-Type');
      const blob = response.body;

      if (blob) {
        const isDocOrDocx = this.isWordDocument(contentType, url);
        const isXlsx = this.isExcelDocument(contentType, url);

        if (isDocOrDocx) {
          this.handleWordDocument(url);
        } else if (isXlsx) {
          this.handleExcelDocument(url);
        } else if (contentType && contentType.includes('application/pdf')) {
          this.handlePdfDocument(blob);
        } else {
          console.error('Unsupported document type:', contentType); // More detailed logging
        }
      } else {
        console.error('Failed to fetch document blob.');
      }
    }, error => {
      console.error('Error fetching document:', error);
    });
  }

  private isWordDocument(contentType: string | null, url: string): boolean {
    return contentType && (
      contentType.includes('application/vnd.openxmlformats-officedocument.wordprocessingml.document') ||
      contentType.includes('application/msword') ||
      url.endsWith('.docx') ||
      url.endsWith('.doc')
    );
  }

  private isExcelDocument(contentType: string | null, url: string): boolean {
    return contentType && (
      contentType.includes('application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') ||
      url.endsWith('.xlsx')
    );
  }

  private handleWordDocument(url: string): void {
    this.safeDocUrl = this.sanitizer.bypassSecurityTrustResourceUrl(this.getGoogleDocsViewerUrl(url));
  }

  private handleExcelDocument(url: string): void {
    this.safeDocUrl = this.sanitizer.bypassSecurityTrustResourceUrl(this.getOfficeOnlineUrl(url));
  }

  private handlePdfDocument(blob: Blob): void {
    const objectUrl = URL.createObjectURL(blob);
    this.safeDocUrl = this.sanitizer.bypassSecurityTrustResourceUrl(objectUrl);
  }

  private getGoogleDocsViewerUrl(url: string): string {
    return `https://docs.google.com/gview?url=${encodeURIComponent(url)}&embedded=true`;
  }

  private getOfficeOnlineUrl(url: string): string {
    return `https://view.officeapps.live.com/op/embed.aspx?src=${encodeURIComponent(url)}`;
  }
}
