import { prop, required } from "@rxweb/reactive-form-validators";

export class ArchivedFolder {
    constructor() {

        this.isChecked = false;
    }
    companyId: number;
    @required()
    label: string;
    @required()
    name:string;
    subItems:[];
    createdAt: Date;
    auditName: string;
    auditType: string;
    auditId: number;
    @prop()
    folderId: number;
    id: string
    // @required()
    parentFolderId: string;
    @prop()
    isChecked: boolean;
}