import { ENTER, COMMA } from '@angular/cdk/keycodes';
import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { RxFormBuilder } from '@rxweb/reactive-form-validators';
import { ResourceManagment } from '../../models/access-request/resource-management.model';
import { Workflow } from '../../models/access-request/workflow.model';
import { GlobalService } from '../../services/global.service';
import { IntegrationsService } from '../../services/integrations.service';
import { ResourceManagmentService } from '../../services/access-request/resource-management.service';
import { WorkflowService } from '../../services/access-request/workflow.service';
import { SnackbarService } from '../../services/snackBarService.service';
import { fromEvent, debounceTime, distinctUntilChanged, Subject } from 'rxjs';
import { ApiResponse } from '../../models/api.response';

@Component({
  selector: 'app-add-application-resource',
  templateUrl: './add-application-resource.component.html',
  styleUrls: ['./add-application-resource.component.scss']
})
export class AddApplicationResourceComponent implements OnInit {
  separatorKeysCodes: number[] = [ENTER, COMMA];
  @ViewChild('poolInput') poolInput: ElementRef<HTMLInputElement>;
  @ViewChild('userInput') userInput: ElementRef<HTMLInputElement>;
  @ViewChild('groupInput') groupInput: ElementRef<HTMLInputElement>;
  resourceManagment: ResourceManagment;
  resourceManagmentForm: FormGroup;
  workflows: Workflow[];
  isSelected: boolean = false;
  resourcePools: any[];
  cloudApps: any[] = [];
  cloudUsers: any[] = [];
  users: any[] = []; selectedGroup: string[];
  isSubmitted = false;

  isUserSelected: boolean = false;
  pageType: string;
  resourceType: string;
  id: number;
  workflowId: number;
  searchCheck: boolean = false;
  private subjectKeyup = new Subject<string>();
  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
    private formBuilder: RxFormBuilder,
    private globalService: GlobalService,
    private snackbarservice: SnackbarService,
    private resourceManagementService: ResourceManagmentService,
    private dialogRef: MatDialogRef<AddApplicationResourceComponent>,
    private workflowService: WorkflowService,
    private integrationService: IntegrationsService) {
    this.resourceManagment = new ResourceManagment();
    this.resourceManagment.resourcePool = [];
    this.workflows = [];
    this.resourcePools = [];
    this.selectedGroup = [];
    this.cloudApps = [];
  }

  ngOnInit(): void {

    this.subjectKeyup.pipe(
      debounceTime(500)).subscribe((keyword) => {
        if(this.resourceType == 'resourcePool'){
          this.searchPools(keyword);
        }else{
          this.searchCloudUsers(keyword);
        }
      });

    this.pageType = this.data.pageType;
    this.resourceType = this.data.resourceType;
    // this.resourceManagment.selectedPool=this.data.selectedPool;
    this.id = this.data.id;
    this.resourceManagment.resourceType = this.data.resourceType;
    if (this.pageType === 'edit') {
      this.resourceManagment.resourceId = this.id;
      this.resourceManagementService.getById(this.resourceManagment.resourceId)
        .subscribe((res: any) => {
          
          this.resourceManagment = res.data;
          this.getWorkflowRules(this.resourceManagment.workflowId);
          this.resourceManagment.selectedPool = this.resourceManagment.resourceType;
        });
    } else {
      this.getWorkflowRules(null);
    }
    this.resourceManagmentForm = this.formBuilder.formGroup(this.resourceManagment);
  }

  cancel() {
    this.dialogRef.close();
  }
  remove(pool): void {
    const index = this.resourceManagment.resourcePool.findIndex(p => p.uuid == pool.uuid);
    if (index >= 0) {
      this.resourceManagment.resourcePool.splice(index, 1);
    }
  }

  getWorkflowRules(id: number) {
    if (this.pageType == 'edit') {
      this.workflowService.getAll({
        companyId: this.globalService.company.id,
        workflowId: this.id,
      })
        .subscribe((res: any) => {
          this.workflows = res.data;
        });
    } else {
      this.workflowService.getAll({
        companyId: this.globalService.company.id,
      })
        .subscribe((res: any) => {
          this.workflows = res.data;
        });
    }
  }

  onUserSelect(user) {
    this.resourceManagment.resourceOwner = user.option.value;
    this.isUserSelected = true;
    this.cloudUsers = [];
  }

  onPoolSelect(event) {
    if (this.resourceManagment.resourcePool.findIndex(p => p.uuid == event.option.value.uuid) > -1) {
      this.snackbarservice.open(`This ${this.resourceManagment.resourceType} is already selected.`, 'X', 'info')
      return;
    }

    this.poolInput.nativeElement.value = '';
    this.resourceManagment.resourcePool.push(event.option.value);
    this.userInput.nativeElement.value = '';
    this.isSelected = true;
    this.resourceManagment.keyword = "";
  }

  searchPools(keyword) {
      this.searchCheck = false;
      let request = {
        keyword: keyword,
        userType: this.globalService.user.userType,
        accessId: this.globalService.user.accessId,
        resourceType: this.resourceManagment.resourceType
      }
      this.resourcePools = [];
      this.integrationService.searchResourcePool(request).subscribe((res: ApiResponse<any[]>) => {
        this.resourcePools = res.data;
        this.searchCheck = true;
      });
  }

  searchCloudUsers(event) {
        this.searchCheck = false;
        let delegate = {
          keyword: event,
          userType: this.globalService.user.userType,
          accessId: this.globalService.user.accessId
        }

        this.cloudUsers = [];
        this.integrationService.getUsers(delegate).subscribe((res: any) => {
          this.cloudUsers = res.data;
          this.searchCheck = true;
        });
  }

  search(keyword , type){
    this.resourceType = type;
    this.subjectKeyup.next(keyword);
  }

  create() {
    this.isSubmitted = true;
    
    this.resourceManagmentForm.get('requestedFor').clearValidators();
    this.resourceManagmentForm.get('requestedFor').updateValueAndValidity();
    this.resourceManagmentForm.get('description').clearValidators();
    this.resourceManagmentForm.get('description').updateValueAndValidity();
    this.resourceManagment.accessId = this.globalService.user.accessId;
    this.resourceManagment.workflowId = this.resourceManagment.workflowId;
    if (this.resourceManagment.selectedPool == 'all')

      this.resourceManagment.resourcePool = [{ name: 'all', uuid: '' }];
    if (this.resourceManagmentForm.invalid) return;
    this.resourceManagment.accessId = this.globalService.user.accessId;

    if (this.pageType == 'edit') {

      this.resourceManagementService.update(null, this.resourceManagment).subscribe((res: any) => {
        this.snackbarservice.open(res.message, 'X', 'success');
        this.dialogRef.close();
      });
    }
    else {
      this.resourceManagementService.add(this.resourceManagment).subscribe((res: any) => {
        this.snackbarservice.open(res.message, 'Close', 'success')
        this.dialogRef.close(true);
      });
    }
  }
}
