<!-- <div class="spiner-example text-center" style="margin: 0 auto">
    <div class="sk-spinner sk-spinner-three-bounce" style="width: 210px;">
        <div class="sk-bounce1"></div>
        <div class="sk-bounce2"></div>
        <div class="sk-bounce3"></div>
        <h1><b>Please wait...</b></h1>
    </div>
</div> -->
<div class="spiner-example text-center" style="margin: 0 auto">
    <div class="sk-spinner sk-spinner-three-bounce" style="width: 210px;">
        <div class="sk-bounce1"></div>
        <div class="sk-bounce2"></div>
        <div class="sk-bounce3"></div>
        <div style="width: 100vw ;height: 100vh;" fxLayoutAlign="center center">
        <h1><b>Please wait...</b></h1>
        </div>
    </div>
</div>