
import { prop, propObject, required } from '@rxweb/reactive-form-validators';
export class Connector {
    id: string;
    @required()
    clientId: string;
    @required()
    clientSecret: string;
    companyId: number
    @required()
    name: string;
    // @required()
    description: string;
    @required()
    username: string;
    @required()
    password: string;
    @required()
    regionName: string;
    @prop()
    status: number;
    @required()
    apiUrl: string;
    // @required()
    syncType: string;
    // @propObject()
    mappingSchema: any = {};
    @prop()
    enable_disable_connector_status: boolean;
    resourceType: string;
    userSelection: [];

}