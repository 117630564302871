import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { ChangePassword } from "../models/auth/change.password.model";
import { User } from "../models/auth/user.model";
import { Register } from "../models/register.model";
import { UserActivation } from "../models/user.activation.model";
import { RestService } from "./rest.service";
import { ConfigService } from "./utils/config.service";

@Injectable()
export class UserService extends RestService<any> {
    constructor(configService: ConfigService, http: HttpClient) {
        super('/users', http, configService);
    }

    userSignup(register: Register) {
        return this.makeRequest('post', '/users/signup', register);
    }

    userActivation(userActivation: UserActivation) {
        return this.makeRequest('post', '/users/user-activation', userActivation);
    }

    verifyEmailLink(token: string) {
        return this.makeRequest('post', `/users/email-link-verification/${token}`, null);
    }
    resendVerifyEmailLink(data) {
        return this.makeRequest('post', `/users/reset-email-link-verification`, data);
    }
    login(login: User) {
        return this.makeRequest('post', '/users/default-login', login);
    }

    samlLogin(login: User) {
        return this.makeRequest('get', '/users/user-type', login);
    }

    changePassword(changePassword: ChangePassword) {
        return this.makeRequest('post', '/users/change-password', changePassword);
    }

    getUsers(data) {
        return this.makeRequest("get", `/users/${data.companyId}`, data);
    }

    sendForgotPasswordEmail(data) {
        return this.makeRequest('post', '/users/forgot-password', data);
    }

    verifyForgotPasswordEmail(token) {
        return this.makeRequest('post', `/users/reset-email-link-verification`, token);
    }

    resetPassword(data) {
        return this.makeRequest('post', '/users/reset-password', data);
    }

    enableDisableUser(data: User) {
        return this.makeRequest('patch', '/enable-disable-user-account', data);
    }
    
    disableUser(data: User) {
        return this.makeRequest('patch', '/users', data);
    }
    reinviteUser(data: User) {
        return this.makeRequest("post", '/users/re-invite-user', data);
    }

    pendingReInvite(data: User) {
        return this.makeRequest("post", '/users/pending-re-invite-user', data);
    }
    addUser(data: User) {
        return this.makeRequest("post", "/addUser", data);
    }

    UpdateStatus(data) {
        return this.makeRequest('patch', '/users/change-user-type', data);
    }

    deleteUser(data) {
        return this.makeRequest('delete', '/users/user-delete', data);
    }
}