import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Company } from "../models/company.model";
import { RestService } from "./rest.service";
import { ConfigService } from "./utils/config.service";

@Injectable()
export class CompanyService extends RestService<Company>{
  constructor(configService: ConfigService, http: HttpClient) {
    super('/company', http, configService);
  }

  companyDetail(companyName: any) {
    return this.makeRequest('get', '/company/get-company-details/' + companyName, null);
  }

  companyEnabledSettings(data) {
    return this.makeRequest('get', '/company/enabled-settings' , data);
  }

  updateJustTimeProvision(data) {
    return this.makeRequest('patch', '/company/update-just-in-time-provisioning', data);
  }

  updateEmailProvider(data) {
    return this.makeRequest('patch', '/company/update-email-provider', data);
  }

  getEmailProvider(data) {
    return this.makeRequest('get', '/company/email-provider', data);
  }

  registerCompanyKey(data){
    return this.makeRequest('post', '/company/register-company-secret-key', data)
  }

  enableDisableSettings(data){
    return this.makeRequest('post', '/company/enable-disable-company-setting', data)
  }

  getCompanySetting(id){ 
    return this.makeRequest('get', `/company/company-settings`, id)
  }
  addCompanySetting(data){
    return this.makeRequest('post', '/company/company-settings', data)
  }
}