import { Location } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { AuthGuard } from './auth-guard.service';
import { AuthService } from './auth.service';
import { GlobalService } from './global.service';

@Injectable()
export class RoleGuard extends AuthGuard {
  constructor(router: Router,
    authService: AuthService,
    location: Location,
    @Inject(PLATFORM_ID) platformId: Object,
    globalService: GlobalService) {
    super(router, authService, location, platformId, globalService);
  }

  override canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const isLoggedIn = super.canActivate(next, state);
    if (isLoggedIn && this.authService.isInRole(next.data['roles']))
      return true;

    this.router.navigateByUrl('/company');
    return false;
  }

  override canActivateChild(next: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return this.canActivate(next, state);
  }
}