import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { RestService } from './rest.service';
import { ConfigService } from './utils/config.service';

@Injectable({ providedIn: 'root' })
export class FolderService extends RestService<any> {

  constructor(configService: ConfigService, http: HttpClient) {
    super('/archived-folders', http, configService);
  }

  createFolders(data) {
    return this.makeRequest("post", '/archived-folders', data);
  }

  updateFolders(data) {
    return this.makeRequest("patch", '/archived-folder', data);
  }

  getArchivedFolder(data) {
    return this.makeRequest("get", '/archived-folder', data);
  }

  getArchivedAudit(data) {
    return this.makeRequest("get", `/archived-audits/${data.folderId}`, data);
  }

  createArchivedAudits(data) {
    return this.makeRequest("post", '/archived-audits', data);
  }

  deleteArchiveAudit(data) {
    return this.makeRequest("delete", `/archived-audits?folderId=${data.folderId}&companyId=${data.companyId}&archivedAuditId=${data.archivedAuditId}`, data)
  }

  unArchiveAudit(data) {
    return this.makeRequest("post", '/unarchived-audits', data)
  }

  deleteFolder(data) {
    return this.makeRequest('delete', `/archived-folder?folderId=${data.folderId}`, data)
  }

}
