import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Audit } from "../models/audit/audit.model";
import { RestService } from "./rest.service";
import { ConfigService } from "./utils/config.service";

@Injectable()
export class IdentityTrustScoreService extends RestService<Audit> {
    constructor(http: HttpClient, configService: ConfigService) {
        super('/identity-trust-score', http, configService);
    }

    getCompanyScoreStats(data) {
        return this.makeRequest('get', '/company-users-zero-score', data);
    }
    
    getUserScores(data) {
        return this.makeRequest('get', '/zero-trust-score', data);
    }

    executeGroupAnomalyScript(data) {
        return this.makeRequest('post', '/zero-trust-score/group-anomaly', data)
    }

    executeSODViolationScript(data) {
        return this.makeRequest('post', '/zero-trust-score/sod-violation', data)
    }

    executeLastReviewedOnScript(data) {
        return this.makeRequest('post', '/zero-trust-score/user-reviewed-score', data)
    }

    updateUserIncidentStatus(data) {
        return this.makeRequest('patch', '/zero-trust-score/update-user-incident-status', data)
    }

}