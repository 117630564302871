<div [class.collapsed]="isCollapsed$ | async" [class.dropdown-open]="dropdownOpen$ | async" [ngClass]="levelClass"
  class="sidenav-item">
  <div *ngIf="item.type === 'subheading' && item.isVisible" fxLayout="row" fxLayoutAlign="space-between center"
    [ngClass]="item.customClass" class="subheading">
    <span>{{ item.name | uppercase }}</span>
    <span *ngIf="item.addButton" fxFlex="30" fxLayoutAlign="center center">
      <mat-icon (click)="addFolder('add', item.itemType)" class="addbutton">add</mat-icon>
    </span>
  </div>
  <ng-container *ngIf="item.type !== 'subheading' && item.isVisible && item.itemType != eNavItemType.archives && item.itemType != eNavItemType.documents">
    <ng-container
      *ngIf="item.subItems?.length > 0 || isFunction(item.routeOrFunction); then functionOrSubItems; else link"></ng-container>
  </ng-container>

  <ng-container *ngIf="item.itemType == eNavItemType.archives || item.itemType == eNavItemType.documents">
    <mat-tree [dataSource]="item.itemType == eNavItemType.archives ? archivesDataSource : documentsDataSource" [treeControl]="treeControl">
      <mat-tree-node  *matTreeNodeDef="let node" matTreeNodePadding [matTreeNodePaddingIndent]="node.paddingIndent">
        <div class="sidenav-item-link"  [routerLinkActiveOptions]="{ exact: node.pathMatchExact || false }" 
        routerLinkActive="active"  style="width: 100%;">
          <div fxFlex="auto" (mouseenter)="node.showMenu = true" (mouseleave)="node.showMenu = false">
            <a fxFlex="auto" fxLayout="row" fxLayoutAlign="space-between center">
              <span fxLayout="row"  [routerLink]="node.url" (click)="navigationOpen(node.url, node.id)" fxLayoutGap="10px">
                <mat-icon style="color:white;">folder_open</mat-icon>
                <span class="cursor">{{node.name}} </span>
              </span>
             
              <button mat-icon-button [matMenuTriggerFor]="menu"
                [style.display]="node.showMenu ? 'inline-block' : 'none'" aria-label="Example icon-button with a menu">
                <mat-icon style="color:white;">more_vert</mat-icon>
              </button>
              <mat-menu #menu="matMenu">
                <button (click)="editFolder('edit', node.name, node.id, node.itemType)" mat-menu-item>
                  <mat-icon>edit</mat-icon>
                  <span>Edit</span>
                </button>
                <button (click)="deleteFolder(node.id, node.itemType)" mat-menu-item>
                  <mat-icon>delete</mat-icon>
                  <span>Delete</span>
                </button>
              </mat-menu>
            </a>
          </div>
        </div>
      </mat-tree-node>
      <mat-tree-node class="sidenav-item-link" *matTreeNodeDef="let childnode;when: hasChild" matTreeNodePadding
      [matTreeNodePaddingIndent]="10" [routerLinkActiveOptions]="{ exact: childnode.pathMatchExact || false }" 
      routerLinkActive="active" >
    
        <div class="mat-tree-node " (mouseenter)="childnode.showMenu = true" (mouseleave)="childnode.showMenu = false">
          <button mat-icon-button matTreeNodeToggle [attr.aria-label]="'toggle ' + childnode.name">
            <mat-icon class="mat-icon-rtl-mirror" style="color:white;">
              {{treeControl.isExpanded(childnode) ? 'arrow_drop_down' : 'arrow_right'}}
            </mat-icon>
          </button>
          <span fxFlex="auto" fxLayout="row" fxLayoutAlign="space-between center">
            <span fxLayout="row" [routerLink]="childnode.url" (click)="navigationOpen(childnode.url, childnode.id)" fxLayoutGap="10px">
              <mat-icon style="color:white;">folder_open</mat-icon>
              <span class="cursor">{{childnode.name}}</span>
            </span>
            <button mat-icon-button [matMenuTriggerFor]="menu"
              [style.display]="childnode.showMenu ? 'inline-block' : 'none'"
              aria-label="Example icon-button with a menu">
              <mat-icon style="color:white;">more_vert</mat-icon>
            </button>
            <mat-menu #menu="matMenu">
              <button (click)="editFolder('edit', childnode.name, childnode.id, childnode.itemType)" mat-menu-item>
                <mat-icon>edit</mat-icon>
                <span>Edit</span>
              </button>
              <button (click)="deleteFolder(childnode.id)" mat-menu-item>
                <mat-icon>delete</mat-icon>
                <span>Delete</span>
              </button>
            </mat-menu>
          </span>
        </div>

      </mat-tree-node>
    </mat-tree>

  </ng-container>

  <ng-template #functionOrSubItems>
    <a (click)="handleClick()" class="sidenav-item-link pointer" fxLayout="row" fxLayoutAlign="start center" matRipple>
      <ng-container *ngTemplateOutlet="linkContent"></ng-container>
    </a>
  </ng-template>

  <ng-template #link>
    <a [routerLinkActiveOptions]="{ exact: getRouterLinkActive(item) }" [routerLink]="item.routeOrFunction"
       class="sidenav-item-link pointer" fxLayout="row" fxLayoutAlign="start center"
      matRipple routerLinkActive="active">
      <ng-container *ngTemplateOutlet="linkContent"></ng-container>
    </a>
  </ng-template>
  <ng-template #linkContent>
    <mat-icon *ngIf="level === 0 " class="icon">{{ item.icon }}</mat-icon>
    <div *ngIf="level > 0" class="text-icon" fxFlex="none"><mat-icon>navigate_next</mat-icon></div>

    <span class="name">{{ item.name }}</span>
    <span fxFlex><!-- fill space --></span>
    <span *ngIf="item.badge" [style.background-color]="item.badgeColor" class="badge">{{
      item.badge }}</span>
    <mat-icon *ngIf="item.subItems && item.subItems.length > 0" [class.rotate]="dropdownOpen$ | async"
      class="expand-indicator">
      expand_more
    </mat-icon>
  </ng-template>

  <div [@dropdownOpen]="dropdownOpen$ | async" class="dropdown" fxLayout="column">
    <fury-sidenav-item *ngFor="let subItem of item.subItems" [item]="subItem" [level]="level+1"></fury-sidenav-item>
  </div>
</div>