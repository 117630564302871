export class ApiResponse<T> {
  allowOverride: boolean;
  constructor() {
    this.errors = [];
  }

  data: T;
  message: string;
  totalRecords: number;
  totalLicenses: number;
  usedLicenses: number;
  errors: ApiError[];
  getErrorsText(): string {
    return this.errors.map(e => e.text).join(' ');
  }

  hasErrors(): boolean {
    return this.errors.length > 0;
  }
}

export class ApiError { code: ErrorCode; text: string; }

export enum ErrorCode {
  UnknownError = 1,
  OrderIsOutdated = 2
}