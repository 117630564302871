<div class="fury-list-table">
    <div class="fury-list-header">
      <div *ngIf="!hideHeader" fxLayout="column">
        <div fxLayout="row" fxLayoutAlign="start">
          <div class="fury-list-name border-0">{{ name }}</div>
        </div>
        <div class="pb-10 pt-2" fxLayout="row" fxLayoutAlign="start" fxLayoutGap="8px" fxLayoutGap.gt-xs="12px">
            <div fxLayout="column" fxLayoutAlign="start" fxFlex="20%">
                <div fxLayout="row" fxLayoutAlign="start center" fxFlex="100%">
                  <mat-form-field class="custom-role-class" fxFlex="auto" appearance="outline">
                      <mat-icon class="fury-list-input-icon" matPrefix>search</mat-icon>
                      <mat-label>Search by Username, Email, Manager</mat-label>
                      <input matInput fxFlex #filter [placeholder]="searchPlaceholder" [value]="searchText">
                  </mat-form-field>
              </div>
            </div>
          <ng-content select=".actions"></ng-content>
        </div>
      </div>
    </div>
    <ng-content></ng-content>
  </div>
  