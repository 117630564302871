export const environment = {
  production: true,
  apiUrl: 'https://api.youattest.com/api/v3',
  samlLoginUrl: 'https://saml.youattest.com/saml/login/',
  ssoRedirectUrl: 'https://subdomain.youattest.com/okta',
  defaultLogin: 'https://subdomain.youattest.com/login',
  errorRedirect: 'https://console.youattest.com',
  samlAzureUrl: 'https://saml.youattest.com/saml/sso/azuresaml?sso&access_id=',
  webSocketUrl: 'wss://api.youattest.com/ws/',
  azuressoUrl: ' https://saml.youattest.com/saml/sso/azuresaml/metadata/',
  azuressoReplyURl: 'https://saml.youattest.com/saml/sso/azuresaml?acs',
  oktaloginRedirectUrl: 'https://subdomain.youattest.com/okta',
  oktalogoutRedirectUrl: 'https://subdomain.youattest.com/company',
  samlSso: 'https://saml.youattest.com/saml/sso/[ENTITY_ID]',
  jumpCloudUrl: 'https://saml.youattest.com/saml/sso/jumpcloud/?sso&access_id=',
  samlUrl: 'https://saml.youattest.com/saml/sso/jumpcloud/{eniityId}?acs',
  pingOneSamlUrl: 'https://saml.youattest.com/saml/sso/pingonecloud/?sso&access_id=',
  pingOneAcsUrl: 'https://saml.youattest.com/saml/sso/pingonecloud/{eniityId}?acs',
  allowedCompanies: [2, 374, 411]
};