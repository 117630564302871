import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'fury-toolbar-info',
  templateUrl: './toolbar-info.component.html',
  styleUrls: ['./toolbar-info.component.scss']
})
export class ToolbarInfoComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

  sendEmail() {
    window.location.href = 'mailto:support@youattest.com';
  }

}
