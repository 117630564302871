<!-- {{pageType == 'add' ? 'New' : 'Edit'}} -->
<form [formGroup]="resourceManagmentForm">
    <div fxLayoutAlign="end">
        <button type="button" class="close" aria-label="Close" mat-button mat-dialog-close>
            <mat-icon> close</mat-icon>
        </button>
    </div>
    <div fxLayout="column" fxLayoutGap="13px">
        <div fxLayoutAlign="center center" mat-dialog-title>
            {{pageType == 'add' ? 'New' : 'Edit'}} {{ resourceManagment.resourceType == 'app' ?'Application':
            'Group' }} Resource
        </div>
        <mat-divider></mat-divider>
        <span>Select {{resourceManagment.resourceType == 'app' ? 'Application': 'Group' }} which you would like to
            enable for access request.</span>
        <mat-radio-group [(ngModel)]="resourceManagment.selectedPool" class="radio" formControlName="selectedPool"
            fxLayout="row" fxLayoutGap="16px" fxLayoutAlign="center center">
            <mat-radio-button value="app" [checked]="'checked'">
                Selected {{resourceManagment.resourceType == 'app' ? 'Application': 'Group' }}
            </mat-radio-button>
            <mat-radio-button value="all">
                All {{ resourceManagment.resourceType == 'app' ? 'Application': 'Group' }}
            </mat-radio-button>
        </mat-radio-group>

        <mat-form-field *ngIf="resourceManagment.selectedPool == 'app'" appearance="outline">
            <mat-chip-list #chipList1>
                <mat-chip *ngFor="let pool of resourceManagment.resourcePool" (removed)="remove(pool)">
                    {{ pool.name }}
                    <button matChipRemove>
                        <mat-icon>cancel</mat-icon>
                    </button>
                </mat-chip>
                <input placeholder="Type to Search in {{ resourceManagment.resourceType == 'app' ?'Application':
                    'Group' }} " #poolInput [matAutocomplete]="auto"
                    [matChipInputFor]="chipList1" [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                    (keyup)="search($event.target['value'], 'resourcePool')">
            </mat-chip-list>

            <mat-autocomplete #auto="matAutocomplete" (optionSelected)="onPoolSelect($event)">
                <mat-option *ngFor="let pool of resourcePools" [value]="pool">
                    <span>{{ pool.name }}</span>
                </mat-option>
                <mat-option *ngIf="searchCheck && !(resourcePools?.length > 0)">
                    No Record found
                </mat-option>
            </mat-autocomplete>
        </mat-form-field>

        <div fxLayout="row" fxLayoutAlign="space-around center">
            <h4>Resource Owner</h4>
            <mat-form-field appearance="outline">
                <mat-label>Type to Search</mat-label>
                <input #userInput [(ngModel)]="resourceManagment.resourceOwner" [matAutocomplete]="autoPool"
                    formControlName="resourceOwner" type="text" matInput placeholder=""
                    (keyup)="search($event.target['value'], 'resourceOwner')" email>

                <mat-autocomplete #autoPool="matAutocomplete" (optionSelected)="onUserSelect($event)">
                    <mat-option *ngFor="let user of cloudUsers" [value]="user.email">
                        <span>{{ user.email }}</span>
                    </mat-option>
                    <mat-option *ngIf="searchCheck && !(cloudUsers?.length > 0)">
                        No Record found
                    </mat-option>
                </mat-autocomplete>
                <mat-error *ngIf="isSubmitted">
                    {{ resourceManagmentForm.get('resourceOwner')['errorMessage'] }}
                </mat-error>
            </mat-form-field>
        </div>
        <div fxLayout="row" fxLayoutAlign="space-evenly center">
            <h4>Approval Workflow</h4>
            <mat-form-field appearance="outline" fxFlex="grow">
                <mat-select disableOptionCentering panelClass="panelSelect" [(ngModel)]="resourceManagment.workflowId"
                    formControlName="workflowId" placeholder="Select">
                    <mat-option selected>Select Workflow for this app</mat-option>
                    <mat-option [value]="workflow.id" *ngFor="let workflow of workflows">{{ workflow.name }}
                    </mat-option>
                </mat-select>
                <mat-error *ngIf="isSubmitted">
                    {{ resourceManagmentForm.get('workflowId')['errorMessage'] }}
                </mat-error>
            </mat-form-field>
        </div>
    </div>
    <mat-dialog-actions fxLayout="row" fxLayoutAlign="end center">
        <button mat-raised-button color="warn" mat-dialog-close>Close</button>
        <button mat-raised-button color="primary" (click)="create()">
            {{ pageType == 'add' ? 'Save': 'Update'}}</button>
    </mat-dialog-actions>
</form>