<!-- <div class="title">{{ current }}</div> -->
<div class="crumbs" fxLayout="row" fxLayoutAlign="start center">
  <a *ngIf="!(authService.isInRole(['Requestor']))" [routerLink]="['/']" class="crumb home">Home</a>
  <div *ngFor="let crumb of crumbs[0]" class="crumb" fxLayout="row" fxLayoutAlign="start center" [routerLink]="[crumb?.link]">
    <mat-icon class="chevron">chevron_right</mat-icon>
    <div class="link">{{ crumb?.name }}</div>
  </div>
  <div class="crumb current" fxLayout="row" fxLayoutAlign="start center">
    <mat-icon class="chevron">chevron_right</mat-icon>
    <div class="current">{{ current }}</div>
  </div>
</div>
