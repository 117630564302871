import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Integration } from "../models/integration.model";
import { RestService } from "./rest.service";
import { ConfigService } from "./utils/config.service";

@Injectable()
export class IntegrationsService extends RestService<Integration>{
  constructor(configService: ConfigService, http: HttpClient) {
    super('/api-integrations', http, configService)
  }

  searchResourcePool(data) {
    return this.makeRequest('get', (`${data.resourceType == 'app' ? '/api-integrations/cloud-apps/' : '/api-integrations/search-cloud-groups/'}`) + data.accessId, data);
  }

  getApps(data) {
    return this.makeRequest('get', `/api-integrations/cloud-apps/${data.accessId}`, data);
  }

  getGroups(data) {
    return this.makeRequest('get', data.auditType != 'ad' ? `/api-integrations/search-cloud-groups/${data.accessId}` : '/ad-audits/search-ad-user-data' , data);
  }

  getUsers(data) {
    return this.makeRequest('get', data.auditType != 'ad' ? '/api-integrations/search-cloud-users/' + data.accessId : '/ad-audits/search-ad-user-data', data);
  }

  getProfileDetail(data) {
    return this.makeRequest("get", `/api-integrations/cloud-user-attributes/${data.accessId}`, data);
  }

  getCloudRoles(data) {
    return this.makeRequest("get", `/api-integrations/get-cloud-roles/${data.accessId}`, data);
  }

  getCompanyIntegrations(data){
    return this.makeRequest("get", `/company-integrations`, data);
  }
}