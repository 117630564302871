export class SidenavItem {
  id?: string;
  name: string;
  icon?: string;
  routeOrFunction?: any;
  parent?: SidenavItem;
  subItems?: SidenavItem[];
  position?: number;
  pathMatchExact?: boolean;
  badge?: string;
  badgeColor?: string;
  type?: 'item' | 'subheading';
  customClass?: string;
  menu?: boolean = false;
  addButton?: boolean = false;
  isVisible?: boolean = true;
  itemType?: eNavItemType;
}

export enum eNavItemType {
  archives = 'archives',
  documents = 'documents'
}