import { Component, OnInit, Inject } from '@angular/core';
import { MatSnackBarRef, MAT_SNACK_BAR_DATA } from '@angular/material/snack-bar';

@Component({
  selector: 'app-snack-bar',
  templateUrl: './snack-bar.component.html',
  styleUrls: ['./snack-bar.component.scss']
})

export class SnackBarComponent implements OnInit {
  icon : string;
  constructor(@Inject(MAT_SNACK_BAR_DATA) public data: any,
  public snackBarRef: MatSnackBarRef<SnackBarComponent>){
  }

  ngOnInit() { 
  this.icon = this.getIcon();
  }

  getIcon() : string {
    switch (this.data.type) {
      case 'success':
        return 'check_circle_outline';
      case 'error':
        return 'error_outline';
      case 'warn':
        return 'warning';
      case 'info':
        return 'info_outline';
    }
    return 'undefined';
  }
}
