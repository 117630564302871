<div class="footer" fxLayout="row">
  <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="24px" fxFlex="grow">
    <!-- <a href="https://1.envato.market/LPZQEV"
       class="action" color="accent" fxFlex="none" id="get-fury" mat-raised-button>
      <mat-icon class="icon">shopping_cart</mat-icon>
      Buy Fury (Angular 12+)
    </a> -->

    <div class="name" fxHide fxShow.gt-sm>
      <strong>Copyright</strong> &copy; {{ copyrightYear }} YouAttest.com
    </div>
  </div>
  <!-- <div fxLayout="row" fxLayoutAlign="start center">
    <button (click)="hide()" mat-icon-button type="button">
      <mat-icon class="close">close</mat-icon>
    </button>
  </div> -->
</div>
