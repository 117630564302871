<div fxLayoutAlign="end center">
    <button mat-icon-button mat-dialog-close>
        <mat-icon>close</mat-icon>
    </button>
</div>
<div mat-dialog-content class="csv-viewer">
    <table mat-table [dataSource]="dataSource" class="w-100 mat-elevation-z8">
        <ng-container *ngFor="let column of displayedColumns" [matColumnDef]="column">
          <th mat-header-cell *matHeaderCellDef>{{ column }}</th>
          <td mat-cell *matCellDef="let element">{{ element[column] }}</td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      </table>
      <mat-paginator [length]="dataSource?.paginator?.length || 0"
                     [pageSize]="pageSize"
                     [pageSizeOptions]="pageSizeOptions">
      </mat-paginator>
</div>
<div mat-dialog-actions align="end">
    <div align="end">
        <button type="button" mat-button mat-dialog-close>CANCEL</button>
    </div>
</div>