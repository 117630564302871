import { email, prop, propArray, required } from "@rxweb/reactive-form-validators";

export class ResourceManagment {
  constructor() {
    this.resourcePool = [];
    this.selectedPool = "app";
  }

  id: number;
  @required()
  selectedPool: string = 'app';
  resourceType: string;
  companyId: number;
  accessId: number;
  // accessDescription: string;
  keyword: string;
  userId: number;
  uuid: string;
  resourceId: number;
  // resourcePool: string;
  @required()
  resourcePool: ResourcePool[];
  @required()
  @email()
  resourceOwner: string;
  @required()
  requestedFor: string;
  creationAt: Date;
  @required()
  workflowId: number;
  submitted: boolean;
  requestor: string = 'another';
  @required()
  description: string;

}

export class ResourcePool {
  uuid: string;
  @required()
  name: string;
}