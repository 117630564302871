
<div class="page background-pattern flex-card" fxLayout="column" fxLayoutAlign="center center" *ngIf="!isLoading">
  <div [@fadeInUp] class="card border-radius elevation">
    <div class="header padding fill-success-contrast" fxLayout="column" fxLayoutAlign="center center" *ngIf="!isNew">
      <div class="logo outline-primary-contrast">
        <div class="outline-primary-contrast">
          <img src="../../../assets/images/atlogo1.png" class="logo" alt="">
        </div>
      </div>
    </div>
    <div class="padding" fxLayout="column" fxLayoutGap="26px" *ngIf="!isNew">
      <form fxFlex="auto" fxLayout="column" [formGroup]="loginForm"
        (ngSubmit)="!user.userType ? checkUserType() : userLogin()">
        <mat-form-field appearance="outline">
          <mat-label>Email Address</mat-label>
          <input matInput required placeholder="Email Address" [(ngModel)]="user.email" formControlName="email">
          <mat-error *ngFor="let message of loginForm.get('email')['errorMessages']">
            {{message}}
          </mat-error>
        </mat-form-field>
        <mat-form-field appearance="outline" *ngIf="otpSent && user.userType?.toLowerCase() == 'admin'">
          <mat-label>Password</mat-label>
          <input matInput [type]="hide ? 'password' : 'text'" required placeholder="Password" [(ngModel)]="user.password"
            formControlName="password">
          <button type="button" mat-icon-button matSuffix (click)="hide = !hide" aria-label="Toggle password visibility">
            <mat-icon>{{ hide ? 'visibility_off' : 'visibility' }}</mat-icon>
          </button>
          <mat-error *ngIf="isSubmitted">
            <span *ngFor="let message of loginForm.get('password')['errorMessages']">
              {{message}}
            </span>
          </mat-error>
        </mat-form-field>

        <div *ngIf="user.userType?.toLowerCase() == 'reviewer'">
          <h3 class="modal-title">One-Time Password</h3>
          <br>
          <p>Thank you for choosing YouAttest. Use the following One Time Password (OTP) to complete your Sign In
            procedure. Please
            provide the One Time Password (OTP) sent to your registered Email</p>
          <div fxLayout="column" fxLayoutGap="8px">
            <mat-form-field appearance="outline">
              <mat-label>Enter One Time Password</mat-label>
              <input [type]="hideOtp ? 'password' : 'text'" matInput required placeholder="Enter One Time Password" [(ngModel)]="user.password"
                formControlName="password">
              <button type="button" mat-icon-button matSuffix (click)="hideOtp = !hideOtp" aria-label="Toggle password visibility">
                <mat-icon>{{ hideOtp ? 'visibility_off' : 'visibility' }}</mat-icon>
              </button>
              <mat-error *ngIf="isSubmitted"> 
                <span *ngFor="let message of loginForm.get('password')['errorMessages']">
                  {{message}}
                </span>
              </mat-error>
            </mat-form-field>
            <div id="countdown" fxLayoutAlign="center stretch"></div>
            <button [disabled]="isButtonDisabled" type="button" mat-button (click)="checkUserType()">Resend OTP</button>
          </div>
        </div>

        <div fxLayout="row" fxLayoutAlign="space-between center" *ngIf="user.userType?.toLowerCase() == 'admin'">
          <a class="caption" [routerLink]="['/auth/forgot-password']">Forgot Password?</a>
        </div>
        <br>
        <button type="submit" color="primary" mat-raised-button>
          {{ user.userType ? 'SIGN IN': 'Next'}}
        </button>
        <br>
        <!-- <p class="secondary-text text-center">
          Don't have an account?<br />
          <a [routerLink]="['/auth/signup']">Create Account</a>
        </p> -->
      </form>
    </div>

    <div [@fadeInUp] class="card border-radius elevation" *ngIf="isNew && factor.factorId == null">
      <div class="card padding" fxLayout="column" fxLayoutGap="8px" id="ibox1">
        <h3 fxFlexAlign="center">Phone Verification</h3>
        <br />
        <h4 fxFlexAlign="start">In order to protect the security of your account. please add your phone
          number. We will send you a text
          messsage with a verification code that you'll need to enter on the next screen. </h4>
        <br>
        <div>
          <form #f="ngForm" [formGroup]="factorForm">
            <mat-form-field appearance="outline">
              <mat-label>Phone Number</mat-label>
              <!-- <span matPrefix>+1 &nbsp;</span> -->
              <input type="tel" matInput placeholder="+1XXXXXXXXXX" formControlName="phoneNumber"
                [(ngModel)]="factor.phoneNumber">
            </mat-form-field>
            <mat-error *ngIf="isPhone"> <span *ngFor="let message of loginForm.get('phoneNumber')['errorMessages']">
                {{message}}
              </span>
            </mat-error>
            <br>
            <div fxFlexAlign="center" class="form-group ">
              <button mat-raised-button color="primary" type="submit" (click)="addFactor()">Verify Phone Number</button>
            </div>
          </form>
        </div>
        <span fxFlexAlign="start"> <strong>Note:</strong> Your number will not be shown publicly.</span>
      </div>
    </div>
      <div [@fadeInUp] class="card border-radius elevation" *ngIf="isActivate" >
        <div class="card padding" fxLayout="column" fxLayoutGap="8px" id="ibox1">
          <strong fxFlexAlign="center">OTP</strong>
          <span fxFlexAlign="center">Thank you for choosing YouAttest. Use the following OTP to complete your Sign In
            procedures. Please provide
            the
            One Time Password (OTP) sent to your registered Mobile Number {{ factor.phoneNumber }}</span>
          <div class="form-group">
            <form fxLayout="column" fxLayoutGap="15px" [formGroup]="factorForm">
              <mat-form-field appearance="outline" fxFlex="grow">
                <mat-label>Enter Passcode:</mat-label>
                <input matInput [(ngModel)]="factor.passCode" formControlName="passCode" placeholder="Enter pass code">
              </mat-form-field>
              <mat-error *ngIf="isPass"> <span *ngFor="let message of factorForm.get('passCode')['errorMessages']">
                  {{message}}
                </span>
              </mat-error>
              <button type="submit" color="primary" mat-raised-button (click)="activateFactor()">Verify </button>
            </form>
          </div>
      </div>
    </div>
  </div>
  <mat-label [style.margin-top]="'10px'">
    <strong>Copyright</strong> &copy; {{ copyrightYear }} YouAttest.com<br />
  </mat-label>
</div>