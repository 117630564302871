import { Component, Inject, Input, OnInit } from '@angular/core';
import { FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router, ActivatedRoute } from '@angular/router';
import { RxFormBuilder } from '@rxweb/reactive-form-validators';
import { fadeInRightAnimation } from '../../animations/fade-in-right.animation';
import { fadeInUpAnimation } from '../../animations/fade-in-up.animation';
import { Integration } from '../../models/integration.model';
import { ApiResponse } from '../../models/api.response';
import { GlobalService } from '../../services/global.service';
import { IntegrationsService } from '../../services/integrations.service';
import { AlertDialogComponent } from '../alert-dialog/alert-dialog.component';
import { AuthService } from '../../services/auth.service';
import { SnackbarService } from '../../services/snackBarService.service';
import { CloudProviderState } from '../../enums/cloudprovider-state.enum';

@Component({
  selector: 'app-integration',
  templateUrl: './integration.component.html',
  styleUrls: ['./integration.component.scss'],
  animations: [fadeInRightAnimation, fadeInUpAnimation]
})
export class IntegrationComponent implements OnInit {
  @Input() public editIntegration: string;

  pageType: string = 'add';
  apiForm: FormGroup;
  integration: Integration;
  submitted: boolean = false
  hide = true;
  isLogoutRequired: boolean;
  ssoEnable: boolean;
  cloudProvider: any;
  CloudProviderState: any = CloudProviderState;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialog: MatDialog,
    private ingrationService: IntegrationsService,
    private formBuilder: RxFormBuilder,
    private globalService: GlobalService,
    private router: Router,
    private authService: AuthService,
    private dialogRef: MatDialogRef<IntegrationComponent>,
    private activatedRoute: ActivatedRoute,
    private snackbarService: SnackbarService,) {
    this.integration = new Integration();
  }

  ngOnInit(): void {
    this.pageType = this.data.pageType;
    this.isLogoutRequired = this.data.isLogoutRequired;
    this.apiForm = this.formBuilder.formGroup(this.integration);
    if (this.data.pageType == 'edit') {
      this.integration = this.data.detail;
    }

    this.ssoEnable = this.integration.singlesignon;
    if (!this.integration.singlesignon) {
      this.integration.singlesignon = false;
    }

    this.cloudProvider = this.data.name;

    this.integration.cloudProvider = this.globalService.user.cloudProvider;
    if (this.integration.cloudProvider == this.CloudProviderState.JumpCloud && !this.integration.idpUrl)
      this.integration.idpUrl = 'https://sso.jumpcloud.com/saml2/saml2';
  }

  addApi() {
    this.validate();
    this.submitted = true;
    if (this.apiForm.invalid) return;
    this.integration.userId = this.globalService.user.id;
    this.integration.companyId = this.globalService.company.id;
    this.ingrationService.add(this.integration).subscribe((res: ApiResponse<number>) => {
      this.dialogRef.close();
      if (this.integration.singlesignon != this.ssoEnable || this.isLogoutRequired) {
        this.dialog.open(AlertDialogComponent, {
          width: '25vw',
          maxHeight: '100vh',
          data: {
            title: 'Relogin required',
            message: 'API integration is completed. Changes would take affect after successful re-authentication. You will be logged out of this tenant now!',
            buttons: 'integration'
          },
        });

        setTimeout(() => {
          this.authService.logout();
        }, 4000);
      }
      else {
        this.snackbarService.open(res.message, 'Close', 'success')
        this.router.navigateByUrl('/integrations/api');
      }
    });
  }

  editApi(status) {
    this.validate();
    if (this.apiForm.invalid) return;
    this.integration.userId = this.globalService.user.id;
    this.integration.companyId = this.globalService.company.id;
    this.ingrationService.update(this.integration.id, this.integration).subscribe((res: ApiResponse<number>) => {


      if (this.integration.singlesignon != this.ssoEnable || this.isLogoutRequired) {
        this.dialog.open(AlertDialogComponent, {
          width: '25vw',
          maxHeight: '100vh',
          data: {
            title: 'Update Integration',
            message: 'Single Sign On integration is completed. Changes would take affect after successful re-authentication. You will be logged out of this tenant now!',
            buttons: 'integration'
          },
        });
        setTimeout(() => {
          this.authService.logout();
        }, 3000);
      }

      this.snackbarService.open(res.message, 'Close', 'success');
      this.dialogRef.close(status);
    });
  }

  delete() {
    this.dialogRef.close();
  }

  validate() {
    this.integration.url = this.integration.url?.replace(/\/+$/, '');
    this.integration.fname = this.integration.fname?.trim() || '';
    this.integration.url = this.integration.url?.trim() || '';
    this.integration.accessKey = this.integration.accessKey?.trim() || '';
    this.submitted = true;
    this.apiForm.controls['x509Cert'].clearValidators();
    this.apiForm.controls['x509Cert'].updateValueAndValidity();
    this.apiForm.controls['idpUrl'].clearValidators();
    this.apiForm.controls['idpUrl'].updateValueAndValidity();
    if (!this.integration.singlesignon) {
      if (this.integration.cloudProvider != this.CloudProviderState.Azure) {
        this.apiForm.controls['entityId'].clearValidators();
        this.apiForm.controls['entityId'].updateValueAndValidity();
        this.apiForm.controls['clientId'].clearValidators();
        this.apiForm.controls['clientId'].updateValueAndValidity();
      } else {
        this.apiForm.controls['url'].clearValidators();
        this.apiForm.controls['url'].updateValueAndValidity();
      }

      this.apiForm.controls['singlesignon'].clearValidators();
      this.apiForm.controls['singlesignon'].updateValueAndValidity();
      this.apiForm.controls['ssoType'].clearValidators();
      this.apiForm.controls['ssoType'].updateValueAndValidity();
      // this.integration.ssoType = 'default';
    } else if (this.integration.ssoType == 'sso') {
      this.apiForm.controls['clientId'].setValidators([Validators.required]);
      this.apiForm.controls['clientId'].updateValueAndValidity();
      this.apiForm.controls['entityId'].clearValidators();
      this.apiForm.controls['entityId'].updateValueAndValidity();
    } else if (this.integration.ssoType == 'saml') {
      this.apiForm.controls['entityId'].setValidators([Validators.required]);
      this.apiForm.controls['entityId'].updateValueAndValidity();
      if (this.integration.cloudProvider === this.CloudProviderState.Azure) {
        this.apiForm.controls['x509Cert'].setValidators([Validators.required]);
        this.apiForm.controls['x509Cert'].updateValueAndValidity();
        this.apiForm.controls['url'].clearValidators();
        this.apiForm.controls['url'].updateValueAndValidity();
      } else {
        this.apiForm.controls['clientId'].clearValidators();
        this.apiForm.controls['clientId'].updateValueAndValidity();
      }

      if (this.integration.cloudProvider == this.CloudProviderState.JumpCloud) {
        this.apiForm.controls['idpUrl'].setValidators([Validators.required]);
        this.apiForm.controls['idpUrl'].updateValueAndValidity();
      }
    }

    if (this.integration.cloudProvider != this.CloudProviderState.PingOne) {
      this.apiForm.controls['environmentId'].clearValidators();
      this.apiForm.controls['environmentId'].updateValueAndValidity();
    }
  }
}
