import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { RestService } from "./rest.service";
import { ConfigService } from "./utils/config.service";

@Injectable()
export class SODRuleService extends RestService<any>{

    constructor(configService: ConfigService, http: HttpClient) {
        super('/sod-rules', http, configService);
    }

    enableDisableRule(data) {
        return this.makeRequest('patch', `/sod-rules`, data);
    }

    updateSODRule(data) {
        return this.makeRequest('put', `/sod-rules`, data);
    }
}