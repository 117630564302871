<div fxLayout="row" fxLayoutAlign="space-between center">
  <div>
    <h2 style="margin-top: 9px; margin-left: 10px;">Document Viewer</h2>
  </div>
  <div>
    <button mat-icon-button mat-dialog-close>
      <mat-icon>close</mat-icon>
    </button>
  </div>
</div>

<mat-divider></mat-divider>

<div mat-dialog-content style="padding-bottom: 0;">
  <div *ngIf="safeDocUrl; else loading">
    <iframe [src]="safeDocUrl"></iframe>
  </div>
  <ng-template #loading>
    <p>Loading document...</p>
  </ng-template>
</div>

<mat-divider></mat-divider>

<div mat-dialog-actions align="end">
  <button mat-button mat-dialog-close>Close</button>
</div>
