import { Injectable } from '@angular/core';
import { Observable, Subject, timer, BehaviorSubject } from 'rxjs';
import { filter, tap, map, take, switchMap, retryWhen, repeat, distinctUntilChanged, skip, } from 'rxjs/operators';
import { webSocket } from 'rxjs/webSocket';
import { environment } from 'src/environments/environment';
import { GlobalService } from './global.service';
const address: string = environment.webSocketUrl;
const reconnectionDelay = 1000;
@Injectable()
export class WebsocketService {
    private status$: Subject<boolean> = new BehaviorSubject<boolean>(false);
    private attemptNr: number = 0;
    private ws: any;

    public messages$: Subject<any> = new Subject<any>();

    constructor(private globalService: GlobalService) {
        this.create();
        this.connectionStatus$.pipe(
            skip(1),
            filter(status => !status),
            tap(() => this.create()),
        ).subscribe();
    }

    public get connectionStatus$(): Observable<boolean> {
        return this.status$.pipe(distinctUntilChanged());
    }

    public create() {
        if (this.ws) {
            this.ws.unsubscribe()
        }
        
        const retryConnection = switchMap(() => {
            this.status$.next(false);
            this.attemptNr = this.attemptNr + 1;

            return timer(reconnectionDelay);
        });

        const openObserver = new Subject<Event>();
        openObserver.pipe(map((_) => true)).subscribe(this.status$);
        const closeObserver = new Subject<CloseEvent>();
        closeObserver.pipe(map((_) => false)).subscribe(this.status$);
        this.ws = webSocket<any>({
            url: address + btoa(this.globalService.user.id.toString()),
            openObserver,
            closeObserver,
        });

        this.ws.pipe(retryWhen((errs) =>
            errs.pipe(retryConnection,
                repeat()))).subscribe(this.messages$);
    }

    message(message: any) {
        this.connectionStatus$.pipe(
            filter(status => status),
            tap(() => this.ws.next(message)),
            take(1)
        ).subscribe();
    }
}