import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FlexLayoutModule } from '@angular/flex-layout';
import { RxReactiveFormsModule } from '@rxweb/reactive-form-validators';
import { MatDialogModule, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatDividerModule } from '@angular/material/divider';
import { MatTabsModule } from '@angular/material/tabs';
import { MatChipsModule } from '@angular/material/chips';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatSelectModule } from '@angular/material/select';
import { MatRadioModule } from '@angular/material/radio';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatTableModule } from '@angular/material/table';
import { MatToolbarModule } from '@angular/material/toolbar';
import { NgxCsvParserModule } from 'ngx-csv-parser';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';
import { NgxExtendedPdfViewerModule } from 'ngx-extended-pdf-viewer';
import { CookieService } from 'ngx-cookie-service';
import { MatPaginatorModule } from '@angular/material/paginator';

// Components
import {
  AlertDialogComponent,
  IntegrationComponent,
  AuditDelegateTasksComponent,
  AddApplicationResourceComponent,
  SnackBarComponent,
  IdleModalComponent,
  PageNotFoundComponent,
  CreateConnectorComponent,
  PdfViewerComponent,
} from './components';

// Services
import {
  SidenavService,
  ThemeService,
  CompanyService,
  UserService,
  AuditService,
  IdentityTrustScoreService,
  ConfigService,
  AuthService,
  IntegrationsService,
  AuthGuard,
  RoleGuard,
  EmailTemplateService,
  WorkflowService,
  ResourceManagmentService,
  TriggersService,
  LicenseManagmentService,
  WebsocketService,
  HttpCancelService,
  SharedSubjectService,
  SODRuleService,
} from './services';

// Modules
import {
  LoadingOverlayModule,
  ScrollbarModule,
  PageLayoutsModule,
  PageLayoutModule,
  ClickOutsideModule,
  FuryCardModule,
  SidebarModule,
  TitleModule,
  ListModule,
  ListExtendedModule,
  PageModule,
  BreadcrumbsModule,
} from './modules';
import { ADSyncSourceService } from './services/ad-sync-source.service';
import { CsvViewerComponent } from './components/csv-viewer/csv-viewer.component';
import { ImageViewerComponent } from './components/image-viewer/image-viewer.component';
import { DocViewerComponent } from './components/doc-viewer/doc-viewer.component';

@NgModule({
  declarations: [
    AlertDialogComponent,
    IntegrationComponent,
    AuditDelegateTasksComponent,
    AddApplicationResourceComponent,
    SnackBarComponent,
    IdleModalComponent,
    PageNotFoundComponent,
    CreateConnectorComponent,
    PdfViewerComponent,
    CsvViewerComponent,
    ImageViewerComponent,
    DocViewerComponent
  ],
  imports: [
    MatPaginatorModule,
    CommonModule,
    LoadingOverlayModule,
    FlexLayoutModule,
    ScrollbarModule,
    FormsModule,
    ReactiveFormsModule,
    RxReactiveFormsModule,
    PageLayoutsModule,
    PageLayoutModule,
    ClickOutsideModule,
    FuryCardModule,
    SidebarModule,
    TitleModule,
    ListModule,
    ListExtendedModule,
    PageModule,
    NgxCsvParserModule,
    NgxMaterialTimepickerModule,
    MatCheckboxModule,
    MatFormFieldModule,
    MatRadioModule,
    MatIconModule,
    MatSelectModule,
    MatAutocompleteModule,
    MatTabsModule,
    MatChipsModule,
    MatDialogModule,
    MatMenuModule,
    MatDividerModule,
    MatInputModule,
    MatButtonModule,
    BreadcrumbsModule,
    MatTableModule,
    NgxExtendedPdfViewerModule,
    MatToolbarModule
  ],
  exports: [
    LoadingOverlayModule,
    FlexLayoutModule,
    ScrollbarModule,
    FormsModule,
    ReactiveFormsModule,
    RxReactiveFormsModule,
    ClickOutsideModule,
    BreadcrumbsModule,
    FuryCardModule,
    SidebarModule,
    TitleModule,
    AlertDialogComponent,
    IntegrationComponent,
    PageLayoutsModule,
    PageLayoutModule,
    CreateConnectorComponent,
    ListModule,
    ListExtendedModule,
    PageModule,
    NgxCsvParserModule,
    AddApplicationResourceComponent,
    NgxMaterialTimepickerModule,
    MatCheckboxModule,
    MatFormFieldModule,
    MatRadioModule,
    MatIconModule,
    MatSelectModule,
    MatAutocompleteModule,
    MatTabsModule,
    MatChipsModule,
    MatDialogModule,
    MatMenuModule,
    MatDividerModule,
    MatInputModule,
    MatButtonModule,
    MatToolbarModule,
  ],
  providers: [
    SidenavService,
    ThemeService,
    CompanyService,
    UserService,
    AuditService,
    IdentityTrustScoreService,
    ConfigService,
    AuthService,
    IntegrationsService,
    CookieService,
    AuthGuard,
    RoleGuard,
    EmailTemplateService,
    WorkflowService,
    ResourceManagmentService,
    TriggersService,
    LicenseManagmentService,
    WebsocketService,
    HttpCancelService,
    SharedSubjectService,
    ADSyncSourceService,
    SODRuleService,
    { provide: MAT_DIALOG_DATA, useValue: {} },
  ],
})
export class SharedModule {}
