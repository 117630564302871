<div fxLayout="row">
    <div fxFlex="100" fxLayoutAlign="center center">
        <div fxLayout="row" fxLayoutAlign="center center">
            <h3 mat-dialog-title>{{ pageType =='add' ? 'New ' : 'Edit' }} {{ itemType === eNavItemType.archives ? 'Archive' : 'Attestation' }} Folder</h3>
        </div>
    </div>
    <div fxLayoutAlign="end center">
        <button mat-icon-button mat-dialog-close>
            <mat-icon>close</mat-icon>
        </button>
    </div>
</div>
<div matDialogContent>
    <form [formGroup]="folderForm" fxflex="100" fxLayout="column">
        <div class="padding" fxLayout="column" fxLayoutGap="8px">
            <mat-form-field fxFlex="grow" appearance="outline">
                <mat-label>New Folder Name:</mat-label>
                <input type="text" matInput placeholder="Enter New Folder Name" formControlName="label"
                    [(ngModel)]="archivedFolder.label" maxlength="25">
                <mat-hint *ngIf="archivedFolder?.label?.length == 25">
                    Folder Name cannot exceed 25 characters.
                </mat-hint>
                <mat-error *ngIf="submitted">
                    <span *ngFor="let message of folderForm.get('label')['errorMessages']">
                        {{message}}
                    </span>
                </mat-error>
            </mat-form-field>
            <mat-checkbox *ngIf="pageType =='add'" class="example-margin" formControlName="isChecked"
                [(ngModel)]="archivedFolder.isChecked">Nested Folder under</mat-checkbox>
            <mat-form-field *ngIf="archivedFolder.isChecked && pageType == 'add'" fxFlex="grow" appearance="outline">
                <mat-label>Select Folder</mat-label>
                <input type="text" aria-label="Number" matInput (input)="filterChanged($event.target.value)"
                    [matAutocomplete]="auto" formControlName="name" [(ngModel)]="archivedFolder.name">
                <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete">
                    <mat-option disabled>Please select</mat-option>

                    <mat-tree [dataSource]="dataSource" *ngIf="nodeSelected"
                        [treeControl]="treeControl">
                        <mat-tree-node *matTreeNodeDef="let node" matTreeNodePadding [matTreeNodePaddingIndent]="10">
                            <span style="cursor: pointer; width: 100%;  margin-left: 10px;" (click)="selectParent(node.id, node.name)">
                                {{node.name}}
                            </span>
                        </mat-tree-node>
                        <mat-tree-node *matTreeNodeDef="let node; when: hasChild" matTreeNodePadding
                            [matTreeNodePaddingIndent]="20">
                            <span style="cursor: pointer; width: 100%; margin-left: 10px;" (click)="selectParent(node.id ,node.name)">
                                {{node.name}}
                            </span>
                        </mat-tree-node>
                    </mat-tree>
                </mat-autocomplete>
                <mat-error *ngIf="submitted">
                    <span *ngFor="let message of folderForm.get('name')['errorMessages']">{{ message }}</span>
                </mat-error>
            </mat-form-field>
        </div>
    </form>
</div>
<div matDialogActions fxLayoutAlign="end">
    <button type="button" mat-button (click)="onClick(false)">CANCEL</button>
    <button type="submit" (click)="createFolder()" color="primary" mat-raised-button>
        {{pageType=='add'?'Create Folder': 'Update Folder'}}
    </button>
</div>