export class SSOLogin {
    token: string;
    companyId: number;
    accessId: number;
    userId: number;
    userName: string;
    userEmail: string;
    userType: string;
    ssoStatus: string;
    trailAlert: boolean;
    // company_id: number;
    // access_id: number;
    // user_id: string;
    // user_name: string;
    // user_email: string;
    // user_type: string;
    // sso_status: string;
    // trial_alert: boolean;
}
