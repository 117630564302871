import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { ListExtendedComponent } from './list-extended.component';
import { MatInputModule } from '@angular/material/input';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    MatInputModule,
    MatIconModule,
    MatMenuModule,
    FlexLayoutModule
  ],
  declarations: [ListExtendedComponent],
  exports: [ListExtendedComponent]
})
export class ListExtendedModule {
}
