import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { RestService } from './rest.service';
import { ConfigService } from './utils/config.service';

@Injectable({ providedIn: 'root' })
export class DocumentFolderService extends RestService<any> {

  constructor(configService: ConfigService, http: HttpClient) {
    super('/pdf-folder', http, configService);
  }

  createFolders(data) {
    return this.makeRequest("post", '/pdf-folder', data);
  }

  updateFolders(data) {
    return this.makeRequest("patch", '/pdf-folder', data);
  }

  getPdfDocumentFolders(data) {
    return this.makeRequest("get", '/pdf-folder', data);
  }

  getAttestationFiles(data) {
    return this.makeRequest("get", data.folderId ?  `/pdf-file/${data.folderId}` : '/pdf-file', data);
  }

  createAttestationFile(data) {
    return this.makeRequest("post", '/pdf-file', data);
  }

  auditTaskAddFile(data) {
    return this.makeRequest("put", '/user-audits/add-file-to-audit-tasks', data);
  }

  deleteDocumentFolder(data) {
    return this.makeRequest('delete', `/pdf-folder?folderId=${data.folderId}`, data)
  }

  deleteFile(data) {
    return this.makeRequest('delete', `/pdf-file?folderId=${data.folderId}&companyId=${data.companyId}&pdfFileId=${data.pdfFileId}`, data)
  }

  deleteFolder(data) {
    return this.makeRequest('delete', `/pdf-folder?folderId=${data.folderId}`, data)
  }
}
