import { HttpClient, HttpErrorResponse } from "@angular/common/http";
import { Observable, map, catchError, of, throwError } from "rxjs";
import { ApiResponse, ErrorCode } from "../models/api.response";
import { ConfigService } from "./utils/config.service";

export class RestService<T>{
  private baseUrl = '';
  constructor(private _url: string, private http: HttpClient, configService: ConfigService) {
    this.baseUrl = configService.getApiURl();
  }

  getAll(data?): Observable<ApiResponse<T[]>> {
    return this.mapAndCatchError(
      this.http.get<ApiResponse<T[]>>(this.baseUrl + this._url + '?' + this.objectToQueryString(data))
    );
  }

  get(data?): Observable<ApiResponse<T>> {
    return this.mapAndCatchError(
      this.http.get<ApiResponse<T>>(this.baseUrl + this._url + '?' + this.objectToQueryString(data))
    );
  }

  getById(id: number | string, data?): Observable<ApiResponse<T>> {
    
    return this.mapAndCatchError(
      this.http.get<ApiResponse<T>>(`${this.baseUrl + this._url}/${id}?${this.objectToQueryString(data)}`)
    );
  }


  add(resource: T): Observable<ApiResponse<number>> {
    return this.mapAndCatchError(
      this.http.post<ApiResponse<number>>(this.baseUrl + this._url, resource)
    );
  }

  update(id: any, resource: T): Observable<ApiResponse<number>> {
    return this.mapAndCatchError(
      this.http.put<ApiResponse<number>>(id ? `${this.baseUrl + this._url}/${id}` : `${this.baseUrl + this._url}`, resource)
    );
  }
  
  delete(id: any): Observable<ApiResponse<number>> {
    return this.mapAndCatchError(
      this.http.delete<ApiResponse<number>>(`${this.baseUrl + this._url}/${id}`)
    );
  }


  // common method
  makeRequest<TData>(method: string, url: string, data: any) : Observable<ApiResponse<TData>> {
    let finalUrl: string = this.baseUrl + url;
    let body: any = null;
    if (method.toUpperCase() == 'GET') {
      finalUrl += '?' + this.objectToQueryString(data);
    }
    else {
      body = data;
    }
    return this.mapAndCatchError<TData>(
      this.http.request<ApiResponse<TData>>(
        method.toUpperCase(), finalUrl, { body: body })
    );
  }

  /////// private methods
  private mapAndCatchError<TData>(response: Observable<ApiResponse<TData>>)
    : Observable<ApiResponse<TData>> {
    return response.pipe(
      map((r: ApiResponse<TData>) => {
        const result = new ApiResponse<TData>();
        Object.assign(result, r);
        return result;
      }),
      catchError((err: HttpErrorResponse) => {
        const result = new ApiResponse<TData>();
        Object.assign(result, err.error);
        if (result.errors.length == 0) {
          result.errors.push({ code: ErrorCode.UnknownError, text: 'Unknown error.' });
        }
        return throwError(result.errors);
      })
    );
  }

  private objectToQueryString(obj: any): string {
    const str = [];
    for (const p in obj) {
      if (obj.hasOwnProperty(p)) {
        str.push(encodeURIComponent(p) + '=' + encodeURIComponent(obj[p]));
      }
    }
    return str.join('&');
  }
}
