<form [@fadeInUp] [formGroup]="apiForm">
    <div fxLayoutAlign="end">
        <!-- <button type="button" class="close" aria-label="Close" mat-button mat-dialog-close>
            <mat-icon> close</mat-icon>
        </button> -->
        <button mat-icon-button mat-dialog-close>
            <mat-icon>close</mat-icon>
        </button>
    </div>
    <div fxLayout="column" fxLayoutAlign="start start" fxLayoutGap="10px">
        <mat-label class="text-center"><strong>{{pageType == 'add' ? 'Add a New' : 'Update' }}
                {{integration.cloudProvider}} Integration </strong></mat-label>
        <mat-form-field appearance="outline">
            <mat-label>Friendly Name</mat-label>
            <input matInput placeholder="Friendly Name:" formControlName="fname" [(ngModel)]="integration.fname">
            <mat-error *ngIf="submitted">
                <span *ngFor="let message of apiForm.get('fname')['errorMessages']">
                    {{message}}
                </span>
            </mat-error>
        </mat-form-field>
        <mat-form-field appearance="outline" *ngIf="integration.cloudProvider != CloudProviderState.Azure">
            <mat-label>{{integration.cloudProvider}} URL</mat-label>
            <input matInput placeholder="{{integration.cloudProvider}} URL" formControlName="url"
                [(ngModel)]="integration.url">
            <mat-error *ngFor="let message of apiForm.get('url')['errorMessages']">
                {{message}}
            </mat-error>
        </mat-form-field>
        <mat-form-field appearance="outline">
            <mat-label>API Key</mat-label>
            <input matInput [type]="hide ? 'password' : 'text'" placeholder="API Key:" formControlName="accessKey"
                [(ngModel)]="integration.accessKey">
            <mat-error *ngIf="submitted"> <span *ngFor="let message of apiForm.get('accessKey')['errorMessages']">
                    {{message}}
                </span>
            </mat-error>
            <button mat-icon-button matSuffix (click)="hide = !hide" [attr.aria-label]="'Hide password'"
                [attr.aria-pressed]="hide">
                <mat-icon>{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
            </button>
        </mat-form-field>
        <mat-form-field *ngIf="integration.cloudProvider == CloudProviderState.PingOne" appearance="outline">
            <mat-label>Environment Id</mat-label>
            <input matInput placeholder="Environment Id:" formControlName="environmentId" [(ngModel)]="integration.environmentId">
            <mat-error *ngIf="submitted"> <span *ngFor="let message of apiForm.get('environmentId')['errorMessages']">
                    {{message}}
                </span>
            </mat-error>
        </mat-form-field>
        <mat-form-field *ngIf="integration.cloudProvider == CloudProviderState.Azure" appearance="outline">
            <mat-label>Tenant Id</mat-label>
            <input matInput placeholder="Tenant Id:" formControlName="entityId" [(ngModel)]="integration.entityId">
            <mat-error *ngIf="submitted"> <span *ngFor="let message of apiForm.get('entityId')['errorMessages']">
                    {{message}}
                </span>
            </mat-error>
        </mat-form-field>
        <mat-form-field *ngIf="integration.cloudProvider == CloudProviderState.Azure || integration.cloudProvider == CloudProviderState.PingOne" appearance="outline">
            <mat-label>Client Id</mat-label>
            <input matInput placeholder="Client Id:" formControlName="clientId" [(ngModel)]="integration.clientId">
            <mat-error *ngIf="submitted"> <span *ngFor="let message of apiForm.get('clientId')['errorMessages']">
                    {{message}}
                </span>
            </mat-error>
        </mat-form-field>
        <!-- *ngIf="integration.cloudProvider != 'OKTA'" -->
        <div fxLayoutGap="10px grid">Enable Single Sign-On
            <mat-checkbox [(ngModel)]="integration.singlesignon" formControlName="singlesignon">
            </mat-checkbox>
        </div>

        <label class="radio-group-label" *ngIf="integration.cloudProvider == CloudProviderState.Okta && integration.singlesignon">Select
            the preferred SSO
            method</label>
        <mat-radio-group *ngIf="integration.singlesignon" aria-label="Select an option" fxLayoutGap="10px grid"
            [(ngModel)]="integration.ssoType" formControlName="ssoType">
            <mat-radio-button *ngIf="integration.cloudProvider == CloudProviderState.Okta" [checked]="integration.ssoType == 'sso'"
                value="sso">Open ID</mat-radio-button>
            <mat-radio-button [checked]="integration.ssoType == 'saml'" value="saml">SAML</mat-radio-button>
        </mat-radio-group>
        <mat-form-field appearance="outline"
            *ngIf="integration.ssoType == 'sso' && integration.cloudProvider == CloudProviderState.Okta && integration.singlesignon">
            <mat-label>Client ID</mat-label>
            <input matInput placeholder="Client ID:" [(ngModel)]="integration.clientId" formControlName="clientId">
            <mat-error *ngIf="submitted"> 
                <span *ngFor="let message of apiForm.get('clientId')['errorMessages']">
                    {{message}}
                </span>
            </mat-error>
        </mat-form-field>
        <mat-form-field appearance="outline" *ngIf="(integration.cloudProvider == CloudProviderState.PingOne || integration.cloudProvider == CloudProviderState.JumpCloud) && integration.ssoType == 'saml' && integration.singlesignon">
            <mat-label>{{ integration.cloudProvider == CloudProviderState.PingOne ? 'Initiate Single Sign-On URL' : 'IDP Url' }}</mat-label>
            <input matInput placeholder="{{ integration.cloudProvider == CloudProviderState.PingOne ? 'Initiate Single Sign-On URL:' : 'IDP Url:' }}" [(ngModel)]="integration.idpUrl" formControlName="idpUrl">
            <mat-error *ngIf="submitted"> <span *ngFor="let message of apiForm.get('idpUrl')['errorMessages']">
                    {{message}}
                </span>
            </mat-error>
        </mat-form-field>
        <mat-form-field appearance="outline" *ngIf="integration.cloudProvider != CloudProviderState.Azure && integration.ssoType == 'saml' && integration.singlesignon">
            <mat-label>SAML Entity ID</mat-label>
            <input matInput placeholder="SAML Entity ID:" [(ngModel)]="integration.entityId" formControlName="entityId">
            <mat-error *ngIf="submitted"> <span *ngFor="let message of apiForm.get('entityId')['errorMessages']">
                    {{message}}
                </span>
            </mat-error>
        </mat-form-field>
        <mat-form-field appearance="outline"
        *ngIf="integration.cloudProvider != CloudProviderState.Okta && integration.ssoType == 'saml' && integration.singlesignon">
        <mat-label>X509 Cert</mat-label>
        <textarea matInput placeholder="X509 Cert:" [(ngModel)]="integration.x509Cert"
            formControlName="x509Cert"></textarea>
        <mat-error *ngIf="submitted"> <span *ngFor="let message of apiForm.get('x509Cert')['errorMessages']">
                {{message}}
            </span>
        </mat-error>
    </mat-form-field>
        <div mat-dialog-actions align="end">
            <button mat-raised-button color="warn" (click)="delete()">Cancel</button>
            <button type="submit" *ngIf="pageType == 'add'" color="primary" mat-raised-button (click)="addApi()">
                Save
            </button>
            <button color="primary" *ngIf="pageType == 'edit'" mat-raised-button (click)="editApi(true)">
                Save Changes
            </button>
        </div>
    </div>
</form>