import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EmailTemplate } from '../models/email-template.model';
import { RestService } from './rest.service';
import { ConfigService } from './utils/config.service';

@Injectable({ providedIn: 'root' })
export class EmailTemplateService extends RestService<EmailTemplate> {
    constructor(configService: ConfigService, http: HttpClient) {
        super('/email-templates', http, configService);
    }

    sendTestEmail(emailTemplate: EmailTemplate) {
        return this.makeRequest('post', '/test-email-templates', emailTemplate)
    }

    updateTemplate(data) {
        return this.makeRequest('put', '/email-templates', data)
    }

    getTemplatebyId(data) {
        return this.makeRequest('get', '/email-templates', data)
    }

}
