<div class="toolbar-user" (furyClickOutside)="onClickOutside()" fxFlex="grow">
  <button class="button" mat-button (click)="toggleDropdown()" [class.open]="isOpen" fxFlex="grow">
    <span fxLayout="row" fxLayoutAlign="start center">
      <span class="name" fxHide fxShow.gt-sm> Welcome, {{ userName }}!</span>
      <mat-icon class="icon" fxHide fxShow.gt-sm>keyboard_arrow_down</mat-icon>
    </span>
  </button>

  <div class="dropdown" [class.open]="isOpen">
    <div class="content">
      <div class="list">
        <!-- <div *ngIf="authService.isInRole(['Admin'])" class="list-item" matRipple
          [routerLink]="['/account/account-settings']">
          <mat-icon class="list-item-icon">settings</mat-icon>
          <span>Config Settings</span>
        </div> -->
        <div *ngIf="authService.isInRole(['Admin'])" class="list-item" matRipple
          [routerLink]="['/account/change-password']">
          <mat-icon class="list-item-icon">lock_reset</mat-icon>
          <span>Change password</span>
        </div>
        <mat-divider></mat-divider>
        <div class="list-item" matRipple (click)="authService.logout();onClickOutside()">
          <mat-icon class="list-item-icon">exit_to_app</mat-icon>
          <span>Logout</span>
        </div>
      </div>
    </div>
  </div>
</div>