<div fxLayoutAlign="end center">
    <button mat-icon-button mat-dialog-close>
        <mat-icon>close</mat-icon>
    </button>
</div>
<div mat-dialog-content class="image-viewer">
    <img *ngIf="imageUrlSafe" [src]="imageUrlSafe">
    <span *ngIf="!imageUrlSafe">Loading...</span>
</div>

<div mat-dialog-actions align="end">
    <div align="end">
        <button type="button" mat-button mat-dialog-close>CANCEL</button>
    </div>
</div>