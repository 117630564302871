import { Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SSOLogin } from 'src/app/shared/models/auth/sso.login.model';
import { CompanyService } from 'src/app/shared/services/company.service';
import { Company } from 'src/app/shared/models/company.model';
import { AuthService } from 'src/app/shared/services/auth.service';
import { GlobalService } from 'src/app/shared/services/global.service';
import { ApiResponse } from 'src/app/shared/models/api.response';
import { isPlatformBrowser } from '@angular/common';
import { SnackbarService } from 'src/app/shared/services/snackBarService.service';
@Component({
  selector: 'app-okta',
  templateUrl: './okta.component.html',
  styleUrls: ['./okta.component.scss']
})
export class OktaComponent implements OnInit {
  ssoLogin: SSOLogin;
  company: Company;
  constructor(private activatedRoute: ActivatedRoute,
    private router: Router,
    private globalService: GlobalService,
    private authService: AuthService,
    private snackbarService: SnackbarService,
    private companyService: CompanyService,
    @Inject(PLATFORM_ID) private platformId: Object) {
    this.ssoLogin = new SSOLogin();
    this.company = new Company();
  }

  ngOnInit(): void {
    var accessToken = this.activatedRoute.snapshot.fragment.split('&access_token=');
    var id_token = accessToken[0].replace('id_token=', '');

    var finalAccessToken = accessToken[1].split('&token_type')[0];
    this.globalService.setItem('id_token', id_token);
    this.globalService.setItem('access_token', finalAccessToken);
    if (isPlatformBrowser(this.platformId)) {
      let companyName = window.location.origin.split('.');
      this.company.companyName = companyName[0].split(':')[1].replace('//', '');
      this.companyService.companyDetail(this.company.companyName).subscribe((res: any) => {
        this.globalService.setCompany('company', JSON.stringify(res.data));
  
        this.ssoLogin.accessId = res.data.accessId;
        this.ssoLogin.companyId = res.data.id;
        this.ssoLogin.token = finalAccessToken;
        this.authService.oktaLogin(this.ssoLogin).subscribe((login: ApiResponse<SSOLogin>) => {
          if (login) {
            this.globalService.setUser('userData', JSON.stringify(login.data));
            // this.snackbar.open(login.message, "Success", {
            //   duration: 3000,
            //   verticalPosition: 'top',
            //   horizontalPosition: 'right',
            // });
            this.snackbarService.open(login.message, 'Close', 'success');

            if (login.data.userType == 'Requestor')
              this.router.navigateByUrl('/access-request/submitted-requests');
            else
              this.router.navigateByUrl('/');
          }
        }, () => {
          // this.router.navigateByUrl('/company');
          this.router.navigateByUrl(`/company?error=${this.activatedRoute.snapshot.fragment['error']}`);

        });
      });
    }
 
  }
}
