import { DelegationWorkflowService } from 'src/app/shared/services/delegate-workflow.service';
import { AccessRequestService } from 'src/app/shared/services/access-request/access-request.service';
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { filter, map, startWith } from 'rxjs/operators';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { SidenavService } from '../shared/services/sidenav.service';
import { ThemeService } from '../shared/services/theme.service';
import { checkRouterChildsData } from '../shared/utils/check-router-childs-data';
import { AuthService } from '../shared/services/auth.service';
import { GlobalService } from '../shared/services/global.service';
import { Subscription } from 'rxjs';
import { FolderService } from '../shared/services/folder.service';
import { SidenavItem, eNavItemType } from '../shared/interfaces/sidenav-item.interface';
import { SharedSubjectService } from '../shared/services/shared-subject.service';
import { CloudProviderState } from '../shared/enums/cloudprovider-state.enum';
import { SidebarDirective } from '../shared/modules/sidebar/sidebar.directive';

@Component({
  selector: 'fury-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss']
})
export class LayoutComponent implements OnInit, OnDestroy {

  @ViewChild('configPanel', { static: true }) configPanel: SidebarDirective;
  jipEnabled: boolean = false;
  delgationWorkflowEnabled: boolean = false;
  subscription: Subscription;
  sidenavOpen$ = this.sidenavService.open$;
  sidenavMode$ = this.sidenavService.mode$;
  sidenavCollapsed$ = this.sidenavService.collapsed$;
  sidenavExpanded$ = this.sidenavService.expanded$;
  quickPanelOpen: boolean;
  sideNavigation$ = this.themeService.config$.pipe(map(config => config.navigation === 'side'));
  topNavigation$ = this.themeService.config$.pipe(map(config => config.navigation === 'top'));
  toolbarVisible$ = this.themeService.config$.pipe(map(config => config.toolbarVisible));
  toolbarPosition$ = this.themeService.config$.pipe(map(config => config.toolbarPosition));
  footerPosition$ = this.themeService.config$.pipe(map(config => config.footerPosition));
  cloudProvider: string;
  auditType: string;
  CloudProviderState: any = CloudProviderState;
  scrollDisabled$ = this.router.events.pipe(
    filter<NavigationEnd>(event => event instanceof NavigationEnd),
    startWith(null),
    map(() => checkRouterChildsData(this.router.routerState.root.snapshot, data => data.scrollDisabled))
  );

  constructor(public sidenavService: SidenavService,
    private themeService: ThemeService,
    private route: ActivatedRoute,
    private folderService: FolderService,
    private globalService: GlobalService,
    private accessRequestService: AccessRequestService,
    private authService: AuthService,
    private delegationWorkflowService: DelegationWorkflowService,
    private router: Router,
    private _sharedSubjectService: SharedSubjectService) {

    this._sharedSubjectService.folderRefresh.subscribe(searchText => {
      this.sidenavService.items = [];
      this.updateNavItems();
    })

    this.cloudProvider = this.globalService.user?.cloudProvider || '';
    this.auditType = this.route.snapshot.params['type'];

    this.accessRequestService.getAccessRequestStatus({ companyId: this.globalService.company.id }).subscribe((res: any) => {

      this.sidenavService.jipEnabled$.next(res.data.status);
    });

    this.delegationWorkflowService.getDelegation(this.globalService.company.id).subscribe((res: any) => {
      this.sidenavService.delgationWorkflowEnabled$.next(res.data[0].delegationWorkflowEnable)
    })

    this.subscription = this.sidenavService.jipEnabled$.subscribe({
      next: (res) => {

        this.jipEnabled = res;
        this.sidenavService.items = [];
        this.updateNavItems();
      }, complete: () => { }, error: () => { }
    });
    this.subscription = this.sidenavService.delgationWorkflowEnabled$.subscribe({
      next: (res) => {
        this.delgationWorkflowEnabled = res;
        this.sidenavService.items = [];
        this.updateNavItems();

      }, complete: () => { }, error: () => { }
    });
  }

  updateNavItems() {
    this.sidenavService.addItems([
      {
        name: 'Dashboard',
        routeOrFunction: '/',
        icon: 'dashboard',
        position: 1,
        pathMatchExact: true,
        isVisible: this.authService.isInRole(['Admin', 'Reviewer'])
      },
      {
        name: 'Identity Trust Score',
        icon: 'show_chart',
        routeOrFunction: '/identity-trust-score',
        position: 2,
        pathMatchExact: true,
        isVisible: this.authService.isInRole(['Admin']) && this.globalService.company.trialStatus == false
      },
      {
        name: 'Audits',
        position: 3,
        type: 'subheading',
        customClass: 'first-subheading',
        isVisible: this.authService.isInRole(['Admin', 'Reviewer'])
      },
      {
        name: 'Auto Audits',
        icon: 'content_paste',
        position: 4,
        pathMatchExact: true,
        isVisible: this.authService.isInRole(['Admin', 'Reviewer']),
        subItems: [
          {
            name: 'Create Audit',
            routeOrFunction: `/audit/app/create`,
            position: 1,
            pathMatchExact: true,
            isVisible: this.authService.isInRole(['Admin']),
          },
          {
            name: 'Application Audits',
            routeOrFunction: '/audit/app',
            position: 2,
            pathMatchExact: true,
            isVisible: this.authService.cloudProviderEnabled()
          },
          {
            name: 'Group Audits',
            routeOrFunction: '/audit/group',
            position: 3,
            pathMatchExact: true,
            isVisible: this.authService.cloudProviderEnabled()
          },
          {
            name: 'Event Audits',
            routeOrFunction: '/audit/event',
            position: 4, 
            pathMatchExact: true,
            isVisible: (this.cloudProvider == this.CloudProviderState.Okta || this.cloudProvider == this.CloudProviderState.Azure) && this.authService.cloudProviderEnabled()
          },
          {
            name: 'User Audits',
            routeOrFunction: '/audit/user',
            position: 5,
            pathMatchExact: true,
            isVisible: true
          }
        ]
      },
      {
        name: 'Audits via Resources',
        icon: 'description',
        position: 5,
        isVisible: this.authService.isInRole(['Admin', 'Reviewer']),
        subItems: [
          {
            name: 'Create Audits via Resources',
            routeOrFunction: '/audit/create/resource/audit',
            position: 1,
            pathMatchExact: true,
            isVisible: this.authService.isInRole(['Admin'])
          },
          {
            name: 'Audits via Resources',
            routeOrFunction: '/audit/resource',
            position: 2,
            pathMatchExact: true,
            isVisible: true
          },
          {
            name: 'Siloed Resources',
            routeOrFunction: '/audit/create/resource/siloed',
            position: 3,
            isVisible: this.authService.isInRole(['Admin'])
          },
          {
            name: 'HR-IAM Resource Variance',
            routeOrFunction: '/audit/create/resource/hr-iam-resource-variance',
            position: 4,
            isVisible: this.authService.isInRole(['Admin'])
          }
        ]
      },
      {
        name: 'SOD Rules',
        position: 6,
        type: 'subheading',
        customClass: 'first-subheading',
        isVisible: this.authService.isInRole(['Admin']) && this.authService.cloudProviderEnabled()
      },
      {
        name: 'SOD Rules',
        icon: 'description',
        position: 7,
        isVisible: this.authService.isInRole(['Admin']) && this.authService.cloudProviderEnabled() && this.authService.ssoEnabled(),
        subItems: [
          {
            name: 'Application SOD Rules',
            routeOrFunction: '/sod-rules/app',
            position: 1,
            pathMatchExact: true,
            isVisible: true
          },
          {
            name: 'Group SOD Rules',
            routeOrFunction: '/sod-rules/group',
            position: 2,
            pathMatchExact: true,
            isVisible: true
          },
        ]
      },
      {
        name: 'Workflows',
        position: 8,
        type: 'subheading',
        customClass: 'first-subheading',
        isVisible: (this.authService.isInRole(['Admin'])) && (this.authService.ssoEnabled())
      },
      {
        name: 'Event Triggers',
        icon: 'event',
        position: 9,
        pathMatchExact: true,
        isVisible: this.authService.isInRole(['Admin']) && (this.cloudProvider == this.CloudProviderState.Okta || this.cloudProvider == this.CloudProviderState.Azure),
        subItems: [
          {
            name: 'Create Trigger',
            routeOrFunction: '/event-trigger/create',
            position: 1,
            pathMatchExact: true,
            isVisible: this.authService.isInRole(['Admin']),
          },
          {
            name: 'Triggers',
            routeOrFunction: '/event-trigger/listTrigger',
            position: 2,
            pathMatchExact: true,
            isVisible: true
          },
          {
            name: 'Event Triggers Logs',
            routeOrFunction: '/event-trigger/eventTrigger',
            position: 3,
            pathMatchExact: true,
            isVisible: true
          }
        ]
      },
      {
        name: 'Delegation Workflow',
        icon: 'description',
        position: 10,
        isVisible: this.authService.isInRole(['Admin']) && (this.authService.ssoEnabled()),
        subItems: [
          {
            name: 'Create Delegation Workflow',
            routeOrFunction: '/delegation-workflow/create',
            position: 1,
            pathMatchExact: true,
            isVisible: true
          },
          {
            name: 'Delegation Workflow List',
            routeOrFunction: '/delegation-workflow/delegation-list',
            position: 2,
            pathMatchExact: true,
            isVisible: true
          },
          {
            name: 'Settings',
            routeOrFunction: '/delegation-workflow/enable-delegation',
            position: 3,
            pathMatchExact: true,
            isVisible: true
          }

        ]
      },
      {
        name: 'Access Request',
        icon: 'open_in_new',
        position: 11,
        pathMatchExact: true,
        isVisible: this.authService.accessRequest() && this.cloudProvider != this.CloudProviderState.JumpCloud && this.cloudProvider != this.CloudProviderState.PingOne,
        subItems: [
          {
            name: 'My Submitted Requests',
            routeOrFunction: '/access-request/submitted-requests',
            position: 1,
            pathMatchExact: true,
            isVisible: this.jipEnabled && this.authService.isInRole(['Admin', 'Requestor', 'Reviewer']),
          },
          {
            name: 'Approval Queue',
            routeOrFunction: '/access-request/approval-queue',
            position: 2,
            pathMatchExact: true,
            isVisible: this.jipEnabled && this.authService.isInRole(['Admin', 'Requestor', 'Reviewer']),
          },
          {
            name: 'Workflow Request',
            routeOrFunction: '/access-request/workflowRequest',
            position: 3,
            pathMatchExact: true,
            isVisible: this.jipEnabled && this.authService.isInRole(['Admin']),
          },
          {
            name: 'Settings',
            routeOrFunction: '/access-request/settings',
            position: 4,
            pathMatchExact: true,
            isVisible: this.authService.isInRole(['Admin'])
          },
        ]
      },
      {
        name: 'Sources',
        position: 12,
        type: 'subheading',
        customClass: 'first-subheading',
        isVisible: this.authService.isInRole(['Admin']),
      },
      // {
      //   name: 'Data Sources',
      //   icon: 'folder_open',
      //   position: 13,
      //   pathMatchExact: true,
      //   isVisible: this.authService.isInRole(['Admin']),
      //   subItems: [
      //     {
      //       name: 'Create New Source',
      //       routeOrFunction: '/data-source/add',
      //       position: 1,
      //       pathMatchExact: true,
      //       isVisible: true
      //     },
      //     {
      //       name: 'Data Sources',
      //       routeOrFunction: '/data-source',
      //       position: 2,
      //       pathMatchExact: true,
      //       isVisible: true
      //     }
      //   ]
      // },
      {
        name: 'On-prem Active Directory',
        icon: 'folder_open',
        position: 14,
        pathMatchExact: true,
        isVisible: this.authService.isInRole(['Admin']),
        subItems: [
          {
            name: 'Active Directory Sync',
            routeOrFunction: '/ad-sync',
            position: 2,
            pathMatchExact: true,
            isVisible: true
          }
        ]
      },
      {
        name: 'Cloud Integrations',
        icon: 'vpn_key',
        position: 14,
        pathMatchExact: true,
        isVisible: this.authService.isInRole(['Admin']),
        subItems: [
          {
            name: 'API Integrations',
            routeOrFunction: '/integrations/api',
            position: 1,
            pathMatchExact: true,
            isVisible: this.authService.cloudProviderEnabled()
          },
          {
            name: 'Integrations Settings',
            routeOrFunction: '/integrations/settings',
            position: 2,
            pathMatchExact: true,
            isVisible: true
          }
        ]
      },
      {
        name: 'Settings',
        position: 15,
        type: 'subheading',
        customClass: 'first-subheading',
        isVisible: this.authService.isInRole(['Admin']),
      },
      {
        name: 'My Account',
        icon: 'border_color',
        position: 16,
        pathMatchExact: true,
        isVisible: this.authService.isInRole(['Admin']),
        subItems: [
          {
            name: 'Change Password',
            routeOrFunction: '/account/change-password',
            position: 1,
            pathMatchExact: true,
            isVisible: true
          },
          {
            name: 'License Management',
            routeOrFunction: '/account/license-management',
            position: 2,
            pathMatchExact: true,
            isVisible: true
          },
          {
            name: 'User Management',
            routeOrFunction: '/account/user-management',
            position: 3,
            pathMatchExact: true,
            isVisible: true
          },
          {
            name: 'Multi-Factor Auth',
            routeOrFunction: '/account/mfa-setting',
            position: 4,
            pathMatchExact: true,
            isVisible: !this.authService.accessRequest(),
          },
          {
            name: 'Messaging Integration',
            routeOrFunction: '/account/message-integration',
            position: 5,
            pathMatchExact: true,
            isVisible: true
          },
          {
            name: 'Email Templates',
            routeOrFunction: '/account/email-templates',
            position: 6,
            pathMatchExact: true,
            isVisible: true
          },
          // {
          //   name: 'API-key',
          //   routeOrFunction: '/account/api-key',
          //   position: 7,
          //   pathMatchExact: true,
          //   isVisible: true
          // }
        ]
      },
      // {
      //   name: 'Settings',
      //   icon: 'settings',
      //   routeOrFunction: '/settings/module-settings/extended-view',
      //   position: 17,
      //   pathMatchExact: true,
      //   isVisible: this.authService.isInRole(['Admin'])
      // },
      {
        name: 'Archives',
        position: 18,
        type: 'subheading',
        customClass: 'first-subheading',
        addButton: true,
        itemType: eNavItemType.archives,
        isVisible: this.authService.isInRole(['Admin']),
      },
      {
        name: 'Attestations File Vault',
        position: 19,
        type: 'subheading',
        customClass: 'first-subheading',
        addButton: true,
        itemType: eNavItemType.documents,
        isVisible: this.authService.isInRole(['Admin']),
      },
    ]);
  }

  ngOnInit() {
    this.updateNavItems();
  }

  openQuickPanel() {
    this.quickPanelOpen = true;
  }

  openConfigPanel() {
    this.configPanel.open();
  }

  closeSidenav() {
    this.sidenavService.close();
  }

  openSidenav() {
    this.sidenavService.open();
  }

  updateChildren(item: SidenavItem): SidenavItem {
    const updatedChildren = item.subItems.map(subItem => this.updateChildren(subItem));
    return { ...item, isVisible: true, subItems: updatedChildren };
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}

