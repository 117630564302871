import { Component, EventEmitter, HostBinding, Input, OnInit, Output } from '@angular/core';
import { map } from 'rxjs/operators';
import { User } from 'src/app/shared/models/auth/user.model';
import { Company } from 'src/app/shared/models/company.model';
import { GlobalService } from 'src/app/shared/services/global.service';
import { ThemeService } from 'src/app/shared/services/theme.service';

@Component({
  selector: 'fury-toolbar',
  templateUrl: './toolbar.component.html',
  styleUrls: ['./toolbar.component.scss']
})
export class ToolbarComponent implements OnInit {

  @Input()
  @HostBinding('class.no-box-shadow')
  hasNavigation: boolean;
  user: User;
  company: Company;

  @Output() openSidenav = new EventEmitter();
  @Output() openQuickPanel = new EventEmitter();

  topNavigation$ = this.themeService.config$.pipe(map(config => config.navigation === 'top'));

  constructor(private themeService: ThemeService, private globalService: GlobalService) {
    this.user = this.globalService.user;
    this.company = this.globalService.company;
  
  }

  ngOnInit() { }


}
