import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { LicenseManagement } from '../models/license/licensemanagment.model';
import { RestService } from './rest.service';
import { ConfigService } from './utils/config.service';
@Injectable({ providedIn: 'root' })
export class LicenseManagmentService extends RestService<LicenseManagement> {
    constructor(configService: ConfigService, http: HttpClient) {
        super('', http, configService);
    }

    getLicense(data) {
        return this.makeRequest('get', '/licenses', data);
    }

    generateLicenseManagementReport(data) {
        return this.makeRequest('post', '/licenses/generate-licenses-management-report', data);
    }
}
