import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { Location } from '@angular/common';
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Router,
  CanActivateChild,
} from '@angular/router';
import { AuthService } from './auth.service';
import { GlobalService } from './global.service';

@Injectable()
export class AuthGuard implements CanActivate, CanActivateChild {
  email = null;
  constructor(
    public router: Router,
    public authService: AuthService,
    private location: Location,
    @Inject(PLATFORM_ID) private platformId: Object,
    private globalService: GlobalService) {
  }

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (this.authService.isAuthenticated()) {
      return true;
    }

    if (this.globalService.user) {
      this.authService.refreshToken().subscribe((res: any) => {
        if (res) {
          var user = this.globalService.user;
          user.accessToken = res.refreshToken;
          this.globalService.setUser('userData', JSON.stringify(user));
        }
      });
    }

    if (state.url.indexOf('?')) {
      var stateUrl = state.url.split('?email=');
      state.url = stateUrl[0];
      this.email = stateUrl[1];
    }

    
    let url = this.location['_locationStrategy']._platformLocation.href.split('.');
    var companyName = url[0].split(':')[1].replace('//', '');
    if (companyName == 'console') {
      
      this.router.navigate(['/company'], {
        queryParams: { returnUrl: state.url },
      });
    } else {
      this.router.navigate(['/login'], {
        queryParams: { returnUrl: state.url, email: this.email },
      });
    }

    return false;
  }

  canActivateChild(next: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return this.canActivate(next, state);
  }
}
