import { Component, Input, OnInit } from '@angular/core';
import { AuthService } from '../../services';

@Component({
  selector: 'fury-breadcrumbs',
  templateUrl: './breadcrumbs.component.html',
  styleUrls: ['./breadcrumbs.component.scss']
})
export class BreadcrumbsComponent {

  @Input() current: string;
  @Input() crumbs: any[] = [];

  constructor(
    public authService: AuthService ) { }
}
