import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { RestService } from '../rest.service';
import { ConfigService } from '../utils/config.service';
import { Workflow } from '../../models/access-request/workflow.model';

@Injectable({ providedIn: 'root' })
export class WorkflowService extends RestService<Workflow> {

  constructor(configService: ConfigService, http: HttpClient) {
    super('/access-requests/workflow', http, configService);
  }

  addOrUpdateWorkFlow(workflow, pageType) {
    if (pageType == 'add') {
      return this.add(workflow);
    } else {
      // return this.update(workflow.id, workflow)
      return this.makeRequest('put','/access-requests/workflow',workflow );
    }
  }

  // addworkflows(data){
  //   return this.makeRequest('get','/access-requests/workflow',data)
  // }
}