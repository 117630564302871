import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable()
export class ConfigService {
    apiURI: string;
    constructor() {
        this.apiURI = environment.apiUrl;
     }

     getApiURl() {
         return this.apiURI;
     }
}
