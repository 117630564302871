import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { GlobalService } from "./global.service";
import { RestService } from "./rest.service";
import { ConfigService } from "./utils/config.service";

@Injectable()
export class TriggersService extends RestService<any>{

    constructor(configService: ConfigService, http: HttpClient, private globalService: GlobalService) {
        super('/event-triggers', http, configService);
    }

    getEventTriggers(data){
        return this.makeRequest("get",'/event-triggers', data);
    }

    getEventTriggersLog(data){
        // return this.makeRequest("get",`/event-triggers/get-event-logs/${data.id}`, data);
        return this.makeRequest("get",'/event-triggers/get-event-logs/' + data.companyId, data);
    }

    deleteEventTriggers(data){
        return this.makeRequest("delete",`/event-triggers/${data.id}?accessId=${data.accessId}`, data);
    }

    createTrigger(data){
        return this.makeRequest(data.pageType == 'add'?'post' : 'put','/event-triggers', data)
    }
}