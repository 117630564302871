import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import * as moment from 'moment';
import { LIST_FADE_ANIMATION } from 'src/app/shared/list.animation';
import { GlobalService } from 'src/app/shared/services/global.service';
import { WebsocketService } from 'src/app/shared/services/socket.service';
import { Utils } from 'src/app/shared/utils/utils';

@Component({
  selector: 'fury-toolbar-notifications',
  templateUrl: './toolbar-notifications.component.html',
  styleUrls: ['./toolbar-notifications.component.scss'],
  animations: [...LIST_FADE_ANIMATION],
  // changeDetection: ChangeDetectionStrategy.OnPush
})
export class ToolbarNotificationsComponent implements OnInit {

  notifications: any[] = [];
  isOpen: boolean;

  constructor(websocketService: WebsocketService,
    private globalService: GlobalService,
    private router: Router
  ) {
    websocketService.messages$.subscribe({
      next: (notifications: any) => {
        notifications?.forEach(notification => {
          if (this.notifications.findIndex(n => n.id == notification.id) == -1)
            this.notifications.push({
              icon: 'notifications',
              name: notification?.taskMeta?.message,
              time: moment(Utils.formatDateTime(notification?.createdAt)).fromNow(),
              url: this.generateUrl(notification),
              read: false,
              colorClass: '',
              error: notification?.taskMeta?.error,
              // error: true,
            });
          // this.notifications = [...this.notifications];
        });
      }, complete: () => { }, error: () => { }
    });
    // websocketService.messages$.subscribe((notifications: any) => {

    // }, err => {
    // });
  }

  generateUrl(notification) {
    if (notification?.taskMeta?.auditType && notification?.taskMeta?.noUsers != 1) {
      const auditType = notification?.taskMeta?.auditType;
      const auditId = notification?.taskMeta?.auditId;
      const detailType = ((auditType == 'user' || auditType == 'resource') && notification?.taskMeta?.extendedView) ? 'detail-extended' : 'detail';
      const reviewAccess = notification?.taskMeta?.reviewAccess;
      return `/audit/${auditType}/${auditId}/${detailType}?reviewAccess=${reviewAccess}`;
    } else if (notification?.taskMeta?.identityTrustScore && notification.taskMeta.identityTrustScore == true && this.globalService.company.trialStatus == false) {
      return '/identity-trust-score';
    }
    return '';
  }

  ngOnInit() {
    this.updateTime();
  }

  markAsRead(notification, event) {
    notification.read = true;
    this.dismiss(notification, event);
    this.router.navigateByUrl(notification.url);
    this.isOpen = false;
  }

  dismiss(notification, event) {
    event.stopPropagation();
    this.notifications.splice(this.notifications.indexOf(notification), 1);
  }

  toggleDropdown() {
    this.isOpen = !this.isOpen;
  }

  onClickOutside() {
    this.isOpen = false;
  }

  markAllAsRead(event) {
    this.notifications = [];
  }

  updateTime() {
    this.notifications.forEach(notification => {
      notification.time = moment(Utils.formatDateTime(notification?.createdAt)).fromNow()
    });

  }
}
