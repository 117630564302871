import * as moment from "moment";

export class Utils {
  public static formatDate(date: any): Date {
    
    if (date === undefined || date === null) {
      return undefined;
    } else {
      return moment(date).toDate();
    }
  }


  public static formatDateTime(date: any): Date {
    if (date === undefined || date === null) {
      return undefined;
    } else {
      return new Date(moment.utc(date, ["YYYY-MM-DD h:mm A"]).local().format("YYYY-MM-DD hh:mm A"));
    }
  }

  public static toTitleCase(input: string): string {
    return input.toLowerCase().replace(/(?:^|\s)\w/g, (match) => match.toUpperCase());
  }
}