<div class="page background-pattern " fxLayout="column" fxLayoutAlign="center center">
    <div [@fadeInUp] class=" border-radius ">
        <div class="header padding" fxLayout="column" fxLayoutGap="100px" fxLayoutAlign="center center">
            <div class="fill-primary-contrast">
                <img src="../../../assets/images/atlogo1.png" class="logo" alt="">
            </div>
            <strong style="font-size: 200px;">404</strong>
           <span fxLayout="column"  fxLayoutGap="40px" fxLayoutAlign="center center"> 
            <h1>Page Not Found</h1>
            <p> The page you were trying to reach couldn't be found on the server.</p>
            <button  (click)="goToDashboard()" color="primary" mat-raised-button>
               Back to Dashboard
            </button></span>
        </div>
    </div>
</div>
  