<div mat-dialog-title>Assign Reviewer(s)</div>
<form>
    <mat-dialog-content>
        <mat-tab-group mat-align-tabs="center" [ngClass]="{'hide-tab-labels': restrictDelegationsCheck()}" (selectedTabChange)="tabChanged($event)">
            <mat-tab label="Assign to Reviewer(s)">
                <br>
                <mat-form-field appearance="outline">
                    <mat-label>Add Reviewer</mat-label>
                    <mat-chip-list #chipList1>
                        <mat-chip *ngFor="let user of reviewer.reviewerDetails" (removed)="remove(user)">
                            {{ user.email }}
                            <button matChipRemove>
                                <mat-icon>close</mat-icon>
                            </button>
                        </mat-chip>
                        <input placeholder="Enter full email address of the reviewer" #userInput
                            [formControl]="stateCtrl" [matAutocomplete]="auto" [matChipInputFor]="chipList1"
                            [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                            (keyup)="search($event.target['value'])">
                    </mat-chip-list>
                    <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selected($event)">
                        <mat-option *ngFor="let user of cloudReviewers" [value]="user">
                            <span>{{ user?.name || user?.email }}</span>
                        </mat-option>
                        <mat-option *ngIf="ssoEnabled && searchCheck && !(cloudReviewers.length > 0)">
                            No Record found
                        </mat-option>
                    </mat-autocomplete>
                    <button *ngIf="stateCtrl?.value" type="button" mat-icon-button (click)="stateCtrl.reset()"
                        matSuffix>
                        <mat-icon>close</mat-icon>
                    </button>
                </mat-form-field>
                <div *ngIf="userType == 'Admin'">
                    <mat-checkbox *ngIf="auditType !='user' && auditType != 'resource'" color="primary"
                        [(ngModel)]="reviewer.reviewAllStatus" name="reviewAllStatus"
                        [ngModelOptions]="{standalone: true}">
                        <strong>
                            All Reviewers must Certify/Revoke the delegated
                            users to complete the audit.
                        </strong>
                    </mat-checkbox>
                    <br>
                    &nbsp; &nbsp; &nbsp; &nbsp; <small *ngIf="auditType !='user' && auditType != 'resource'">If
                        unchecked,
                        any reviewer can Certify/Revoke the users to complete
                        the audit.</small>
                </div>
            </mat-tab>
            <mat-tab label="Assign to Reviewer Group" [disabled]="userType != 'Admin' || !ssoEnabled">
                <span *ngIf="(reviewer.groupMembers < 1 || reviewer.groupMembers > 10) && isVisible"
                    fxLayoutAlign="center" class="status background-success color-success-contrast">
                    <div class="w-100" fxLayout="row" fxLayoutAlign="space-between center">
                        <span>
                            {{ reviewer.groupMembers > 10 ? 'Group members are more than 10 users.'
                            : 'Group should have at least one user.' }}
                        </span>
                        <button type="button" mat-icon-button matSuffix (click)="cancel()">
                            <mat-icon>close</mat-icon>
                        </button>
                    </div>
                </span>
                <br>
                <mat-form-field class="example-full-width" appearance="outline">
                    <mat-label>Select Group</mat-label>
                    <input type="text" placeholder="Search Group here by name" matInput #groupInput
                        [(ngModel)]="reviewer.keyword" name="keyword" [formControl]="stateCtrl" [matAutocomplete]="auto"
                        (keyup)="search($event.target['value'])">
                    <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete" (optionSelected)="selected($event)">
                        <mat-option *ngFor="let group of cloudReviewers" [value]="group">
                            {{group.name}}
                        </mat-option>
                        <mat-option *ngIf="!(cloudReviewers.length > 0)">
                            No Record found
                        </mat-option>
                    </mat-autocomplete>
                    <button *ngIf="stateCtrl?.value" type="button" mat-icon-button (click)="stateCtrl.reset()"
                        matSuffix>
                        <mat-icon>close</mat-icon>
                    </button>
                </mat-form-field>
                <mat-checkbox *ngIf="auditType !='user' && auditType != 'resource'" color="primary"
                    [(ngModel)]="reviewer.reviewAllStatus" name="reviewAllStatus" [ngModelOptions]="{standalone: true}">
                    <strong>All Reviewers in the group must Certify/Revoke
                        the delegated users to complete the audit.</strong>
                </mat-checkbox>
                <div *ngIf="auditType == 'resource' && userType == 'Reviewer'">
                    <mat-checkbox color="primary" [(ngModel)]="reviewer.allowOverride" name="allowOverride"
                        [ngModelOptions]="{standalone: true}">
                        <strong>
                            Allow Reviewer to Perform action on all User Roles.
                        </strong>
                    </mat-checkbox>
                </div>
            </mat-tab>
        </mat-tab-group>
    </mat-dialog-content>
    <mat-dialog-actions fxLayout="row" fxLayoutAlign="end center">
        <div>
            <div fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="5px" class="modal-footer">
                <button mat-raised-button color="warn" (click)="close()">Close</button>
                <button *ngIf="selectedTab == 'User'" color="primary" mat-raised-button (click)="assignUser()"
                    [disabled]="reviewer.reviewerDetails.length < 1">Assign</button>
                <button *ngIf="selectedTab == 'Group'" color="primary" mat-raised-button (click)="assignGroup()"
                    [disabled]="reviewer.groupMembers < 1 || reviewer.groupMembers > 10">Assign</button>
            </div>
        </div>
    </mat-dialog-actions>
</form>