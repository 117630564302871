<div class="navigation-item-container">
  <a (click)="handleClick.emit(item)" class="navigation-item"
     fxLayout="row"
     fxLayoutAlign="start center"
     matRipple
     [ngClass]="{'active' : item?.routeOrFunction == '/audit/app/create'}"
     routerLinkActive="active">
    <mat-icon class="icon">{{ item.icon }}</mat-icon>
    <span class="name">{{ item.name }}</span>
  </a>
</div>

