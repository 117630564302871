import { Injectable, NgZone } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SnackBarComponent } from '../components';

@Injectable({
  providedIn: 'root'
})
export class SnackbarService {

  constructor(
    public snackbar: MatSnackBar,
    private zone: NgZone,
  ) { }

  public open(displayMessage: string, buttonText: string, messageType: 'error' | 'success' | 'warn' | 'info') {
    this.snackbar.openFromComponent(SnackBarComponent, {
      duration: messageType == 'success' ? 4000 : 5000,
      horizontalPosition: 'end',
      verticalPosition: 'top',
      panelClass: messageType,
      data: {
        message: displayMessage,
        buttonText: buttonText,
        type: messageType
      }
    });
  }
}

