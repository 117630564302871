import { SharedSubjectService } from './../../../shared/services/shared-subject.service';
import { SnackbarService } from 'src/app/shared/services/snackBarService.service';
import { FolderService } from 'src/app/shared/services/folder.service';
import { MatDialog } from '@angular/material/dialog';
import { CreateFolderComponent } from './../../../folder/create-folder/create-folder.component';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import isFunction from 'lodash-es/isFunction';
import { SidenavItem, eNavItemType } from 'src/app/shared/interfaces/sidenav-item.interface';
import { SidenavService } from 'src/app/shared/services/sidenav.service';
import { AlertDialogComponent } from 'src/app/shared/components/alert-dialog/alert-dialog.component';
import { ApiResponse } from 'src/app/shared/models/api.response';
import { GlobalService } from 'src/app/shared/services/global.service';
import { FlatTreeControl, NestedTreeControl } from '@angular/cdk/tree';
import { MatTreeFlatDataSource, MatTreeFlattener, MatTreeNestedDataSource } from '@angular/material/tree';
import { ArchivedFolder } from 'src/app/shared/models/folder/create-folder.model';
import { AuthService } from 'src/app/shared/services/auth.service';
import { DocumentFolderService } from 'src/app/shared/services/document-folder.service';
interface ArchiveFolderList {
  id: string;
  name: string;
  subItems?: ArchiveFolderList[];
  url: string;
  itemType: eNavItemType;
}

interface TreeFlatNode {
  expandable: boolean;
  name: string;
  level: number;
  url: string;
  id: string;
  itemType: eNavItemType;
}

@Component({
  selector: 'fury-sidenav-item',
  templateUrl: './sidenav-item.component.html',
  styleUrls: ['./sidenav-item.component.scss'],
  animations: [
    trigger('dropdownOpen', [
      state('false', style({
        height: 0
      })),
      state('true', style({
        height: '*'
      })),
      transition('false <=> true', animate('300ms cubic-bezier(.35, 0, .25, 1)'))
    ])
  ]
})
export class SidenavItemComponent implements OnInit {

  @Input('item') item: SidenavItem;
  @Input('level') level: number;
  @Output() isDeleted = new EventEmitter<any>();
  isCollapsed$ = this.sidenavService.collapsed$;
  dropdownOpen$: Observable<boolean>;
  folderId: any;
  folderMenu: boolean = false
  isSelectedMap: Map<string, boolean> = new Map<string, boolean>();
  deleteArchiveFolderMessage = 'Are you sure you want to delete this Folder, deleting the Folder will delete all Archive Audits and nested Folders too?';
  deleteDocumentFolderMessage = 'Are you sure you want to delete this Folder, deleting the Folder will delete all Attestation files and nested Folders too?'

  private _transformer = (node: ArchiveFolderList, level: number) => {
    this.isLastChild = node.subItems === undefined || node.subItems.length === 0;
    return {
      expandable: !this.isLastChild,
      name: node.name,
      url: node.url,
      id: node.id,
      level: level,
      itemType: node.itemType,
      paddingIndent: this.isLastChild ? 5 : 0,
    };
  }

  treeControl = new FlatTreeControl<TreeFlatNode>(
    node => node.level, node => node.expandable);

  treeFlattener = new MatTreeFlattener(
    this._transformer, node => node.level, node => node.expandable, node => node.subItems);

  archivesDataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);
  documentsDataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);
  otherFunction: boolean = false;
  isLastChild: boolean;
  eNavItemType = eNavItemType;
  constructor(private sidenavService: SidenavService,
    private dialog: MatDialog,
    private router: Router,
    private folderService: FolderService,
    private documentFolderService: DocumentFolderService,
    private snackbarService: SnackbarService,
    private globalService: GlobalService,
    private sharedSubjectService: SharedSubjectService,
    private authService: AuthService
  ) {

    this.router.events.subscribe((val: any) => {
      if (val?.url?.includes(this.item.routeOrFunction) && this.item.routeOrFunction != '/' && val?.url?.includes('?')) {
        this.sidenavService.toggleItemOpen(this.item);
      }
    });

    this.dropdownOpen$ = this.sidenavService.currentlyOpen$.pipe(
      map(currentlyOpen => this.item.subItems && this.item.subItems.length > 0 && currentlyOpen.indexOf(this.item) > -1)
    );
  }

  get levelClass() {
    return `level-${this.level}`;
  }

  ngOnInit() {

    if (this.item.itemType == eNavItemType.archives && this.authService.isInRole(['Admin'])) {
      this.getArchiveFolder();
    }

    if (this.item.itemType == eNavItemType.documents && this.authService.isInRole(['Admin']) ) {
      this.getDocumentFolders();
    }
    
    if (this.router.url?.includes(this.item.routeOrFunction) && this.item.routeOrFunction != '/') {
      this.sidenavService.toggleItemOpen(this.item);
    }
  }

  getRouterLinkActive(item: any) {
    if ((this.router?.url == item.routeOrFunction && item.routeOrFunction != '/') ||
      (this.router.url?.includes('?') && this.router.url?.includes(this.item.routeOrFunction) && item.routeOrFunction != '/'))
    {
      return false;
    }
    else {
      return item.pathMatchExact;
    }
  }

  addFolder(type, itemType) {
    this.dialog.open(CreateFolderComponent, {
      width: '45vw',
      data: {
        pageType: type,
        itemType
      }
    })
  }

  editFolder(type, name, id, itemType) {
    this.dialog.open(CreateFolderComponent, {
      width: '45vw',
      data: {
        pageType: type,
        folderName: name,
        folderId: id,
        itemType: eNavItemType[itemType]
      }

    })
  }


  deleteFolder(id, itemType) {
    this.dialog.open(AlertDialogComponent, {
      width: '25vw',
      maxHeight: '100vh',
      data: {
        title: 'Delete',
        message: itemType === eNavItemType.archives ? this.deleteArchiveFolderMessage : this.deleteDocumentFolderMessage,
        buttons: 'delete'
      }
    }).afterClosed().subscribe((res) => {
      if (res == true) {
        let req = {
          folderId: id,
        }
        const folderService = itemType === eNavItemType.archives
        ? this.folderService
        : this.documentFolderService;

        folderService.deleteFolder(req).subscribe((res: any) => {
          this.snackbarService.open(res.message, 'Close', 'success')
          this.sharedSubjectService.folderRefresh.next('')
          this.router.navigateByUrl('/')

        });
      }
    });
  }

  isFunction(routeOrFunction: string[] | Function) {
    return isFunction(routeOrFunction);
  }

  handleClick() {

    if (this.item.subItems && this.item.subItems.length > 0) {
      this.sidenavService.toggleItemOpen(this.item);
    } else if ((typeof this.item.routeOrFunction === 'string' || this.item.routeOrFunction instanceof String)) {
      this.router.navigate([this.item.routeOrFunction]);
    } else if (typeof this.item.routeOrFunction === 'function' || this.item.routeOrFunction instanceof Function) {
      this.item.routeOrFunction();
    } else {
      throw Error('Could not determine what to do, Sidenav-Item has no routeOrFunction set AND does not contain any subItems');
    }
  }



  navigationOpen(url: string, nodeId: string | null) {
    this.router.navigateByUrl(url);
  }

  getArchiveFolder() {
    
    const req = {
      companyId: this.globalService.company.id,
      offset: 0,
      limit: 50,
    };
    this.folderService.getArchivedFolder(req).subscribe((res: ApiResponse<any[]>) => {
      this.archivesDataSource.data = res.data;
    });
  }

  getDocumentFolders() {
    
    const req = {
      companyId: this.globalService.company.id,
      offset: 0,
      limit: 50,
    };
    this.documentFolderService.getAll(req).subscribe((res: ApiResponse<any[]>) => {
      this.documentsDataSource.data = res.data;
    });
  }

  hasChild = (_: number, node: TreeFlatNode) => node.expandable;

  getTextIcon(item: SidenavItem) {
    let result = '';

    if (item) {
      const name = item.name.split(' ');

      if (name.length > 0) {
        result += name[0].charAt(0).toUpperCase();
      }

      if (name.length > 1) {
        result += name[1].charAt(0).toLowerCase();
      }

      if (name.length === 1) {
        result += name[0].charAt(1).toLowerCase();
      }
    }

    return result;
  }
}
