import { maxLength, notEmpty, required } from "@rxweb/reactive-form-validators";


export class Integration {

    id: number;
    @maxLength({value: 50})
    @notEmpty()
    fname: string;

    @required() 
    idpUrl: string;
    @required() 
    url: string;
    @required() 
    accessKey: string;
    companyId: number;
    @required()
    clientId: string;
    @required()
    entityId: string;
    userId: number;
    @required()
    ssoType: string;
    @required()
    x509Cert: string;
    cloudProvider: string;

    @required()
    singlesignon: boolean = false; // boolean to number

    @required()
    environmentId: string;
}