import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DelegationWorkflow } from '../models/delegation-workflow.model';
import { RestService } from './rest.service';
import { ConfigService } from './utils/config.service';

@Injectable({ providedIn: 'root' })
export class DelegationWorkflowService extends RestService<DelegationWorkflow> {

    constructor(configService: ConfigService, http: HttpClient) {
        super('/delegation-workflow/delegation-workflow', http, configService);
    }


    getDelegation(companyId: number) {
        return this.makeRequest('get', '/delegation-workflow/enable-disable-delegation-workflow', { companyId })
    }

    enableDisableDelegation(data) {
        return this.makeRequest('post', '/delegation-workflow/enable-disable-delegation-workflow', data)
    }
    deletedelegation(id) {
        return this.makeRequest('delete', `/delegation-workflow/delegation-workflow?workflowId=${id}`, null)
    }
    updateDelegation(data){
        return this.makeRequest('put', '/delegation-workflow/delegation-workflow', data)
    }
}