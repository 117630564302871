import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { RestService } from '../rest.service';
import { ConfigService } from '../utils/config.service';
import { Workflow } from '../../models/access-request/workflow.model';

@Injectable({ providedIn: 'root' })
export class AccessRequestService extends RestService<Workflow> {

  constructor(configService: ConfigService, http: HttpClient) {
    super('/access-requests', http, configService);
  }

  getCustomAccessRequest(data) {
    return this.makeRequest('get', '/access-requests', data);
  }

  getCustomManagementResources(data) {
    return this.makeRequest('get', '/access-requests/custom-resource-management', data)
  }

  createAccessRequest(data) {
    return this.makeRequest('post', '/access-requests', data)
  }

  getQueuedRequests(data) {
    return this.makeRequest('get', '/access-requests/request-queue', data)
  }

  submitAccessRequest(data) {
    return this.makeRequest('put', '/access-requests/submit-action-for-request', data)
  }
  
  getAccessRequestStatus(data) {
    return this.makeRequest('get', '/access-requests/get-access-module-status', data)
  }
}
