<div class="sidenav" fxLayout="column">
  <div class="sidenav-toolbar" fxLayout="row" fxLayoutAlign="start center">
    <div *ngIf="!(expanded == false && collapsed == true)" class="header padding" fxLayout="column"
      fxLayoutAlign="center center">
      <div class="fill-primary-contrast">
        <a routerLink="/"><img src="../../../assets/images/atlogo.png" class="dashboardlogo" alt=""></a>
      </div>
    </div>

    <div *ngIf="expanded == false && collapsed == true " class="header" fxLayout="column" fxLayoutAlign="center center">
      <div class="fill-primary-contrast">
        <img src="../../../assets/images/clogo.png" class="dashboardlogo" style="width: 40px;" alt="">
      </div>
    </div>
    <span fxFlex>
      <!-- fill space -->
    </span>
    <div fxFlexAlign="center" fxHide fxShow.gt-sm>
      <button (click)="toggleCollapsed()" class="sidenav-toolbar-collapse-toggle" mat-icon-button>
        <mat-icon *ngIf="!collapsed" class="sidenav-toolbar-collapse-icon">radio_button_checked</mat-icon>
        <mat-icon *ngIf="collapsed" class="sidenav-toolbar-collapse-icon">radio_button_unchecked</mat-icon>
      </button>
    </div>
  </div>
  <fury-scrollbar class="sidenav-items" fxFlex="auto">
    <fury-sidenav-item (isDeleted)="receiveMessage($event)" *ngFor="let item of items$ | async" [item]="item" [level]="0"></fury-sidenav-item>
  </fury-scrollbar>
</div>