import { HttpClient } from '@angular/common/http';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { User } from '../models/auth/user.model';
import { SSOLogin } from '../models/auth/sso.login.model';
import { SAMLLogin } from '../models/auth/saml.login.model';
import { environment } from 'src/environments/environment';
import { FactorSetting } from '../models/auth/factor-setting.modal';
import { Register } from "../models/register.model";
import { RestService } from "./rest.service";
import { ConfigService } from './utils/config.service';
import { Company } from '../models/company.model';
import { GlobalService } from './global.service';
import { ForgotPassword } from '../models/auth/forgot.password';
import { ResetPassword } from '../models/auth/reset.password.model';
import { ChangePassword } from '../models/auth/change.password.model';
import { UserActivation } from '../models/user.activation.model';
import { UserVerification } from '../models/user.verification.model';
import { ResetVerification } from '../models/auth/reset.verification.model';
import { JwtHelperService } from '@auth0/angular-jwt';
import { isPlatformBrowser } from '@angular/common';

@Injectable({ providedIn: 'root' })
export class AuthService extends RestService<Register> {

  constructor(
    configService: ConfigService,
    http: HttpClient,
    @Inject(PLATFORM_ID) private platformId: Object,
    private globalService: GlobalService,
    public jwtHelper: JwtHelperService,
  ) {
    super('/auth', http, configService);
  }

  userSignup(user: Register) {
    return this.makeRequest('post', '/userSignup', user);
  }

  login(user: User) {
    return this.makeRequest('post', '/users/default-login', user);
  }

  oktaLogin(ssoLogin: SSOLogin) {
    return this.makeRequest('post', '/users/sso-login', ssoLogin);
  }

  samlLogin(samlLogin: SAMLLogin) {
    return this.makeRequest('post', '/users/saml-login', samlLogin);
  }

  forgotPassword(forgotPassword: ForgotPassword) {
    return this.makeRequest('post', '/forgotpassword', forgotPassword);
  }

  changePassword(forgotPassword: ChangePassword) {
    return this.makeRequest('post', '/changePassword', forgotPassword);
  }

  resetPassword(resetPassword: ResetPassword) {
    return this.makeRequest('post', '/resetpassword', resetPassword);
  }

  checkforgotVfCode(resetVerification: ResetVerification) {
    return this.makeRequest('post', '/checkforgotVfCode', resetVerification);
  }

  userActivation(userActivation: UserActivation) {
    return this.makeRequest('post', '/userActivation', userActivation);
  }

  checkVfCode(userVerification: UserVerification) {
    return this.makeRequest('post', '/checkVfCode', userVerification);
  }

  checkUserType(data) {
    return this.makeRequest('get', '/users/user-type/', data);
  }

  // Factor Settings APIs

  factorSettings(factorSetting: FactorSetting) {
    
    return this.makeRequest('post', '/factor-settings', factorSetting);
  }

  enrollFactor(factorsettings: FactorSetting) {
    return this.makeRequest('post', '/enroll-factor', factorsettings);
  }

  getFactorSettings(factorsettings: FactorSetting) {
    return this.makeRequest('get', '/get-factor-setting', factorsettings);
  }

  // Factor Settings Activation APIs

  activateFactor(factorsettings: FactorSetting) {
    return this.makeRequest('post', '/activate-factor', factorsettings);
  }

  verifyFactor(data) {
    return this.makeRequest('post', '/verify-factor-challenge', data);
  }

  factorChallenege(factorsettings: FactorSetting) {
    return this.makeRequest('post', '/factor-challenge', factorsettings);
  }

  public isAuthenticated() {
    if (isPlatformBrowser(this.platformId)) {
      return !this.jwtHelper.isTokenExpired(
        this.globalService.user?.accessToken || ''
      );
    }

    return false;
  }

  isInRole(roles: string[]) {
    return roles?.includes(this.globalService.user?.userType || '');
  }

  
updateRefreshToken(data){
  return this.makeRequest('post', '/refresh_token', data);
}
  refreshToken() {
   
    return this.makeRequest('post', '/refresh_token', {
      user_id: this.globalService.user.id,
    });
  }
  getRefreshToken(data) {
    return this.makeRequest('get', '/refresh_token', data);
  }

  public logout(): void {
    if (isPlatformBrowser(this.platformId)) {
      var company: Company = this.globalService.company;
      const idToken = this.globalService.getItem('id_token');
      this.globalService.removeCompany('company');
      this.globalService.removeUser('userData');
      if (idToken) {
        this.globalService.removeItem('id_token');
        var redirectUrl = environment.errorRedirect;
        redirectUrl =
          redirectUrl.replace(
            redirectUrl.split('//')[1].split('.')[0],
            company.companyName
          ) + '/company';

        var logoutUrl = `${company.url}/oauth2/default/v1/logout?id_token_hint=${idToken}&post_logout_redirect_uri=${redirectUrl}`;
        window.location.href = logoutUrl;
      } else {
        window.location.href = environment.errorRedirect;
      }
    }
  }

  jipEnabled() {
    return this.globalService.user?.accessRequestEnable;
    // return this.globalService.user?.isJip;
  }

  cloudProviderEnabled() {
    return !!this.globalService.user?.cloudIntegrationEnable;
  }

  auditRequest() {
    return this.globalService.company?.legacyAuditsEnable;
  }

  accessRequest() {
    return this.globalService.user?.ssoStatus;
  }

  ssoEnabled() {
    return this.globalService.user?.ssoStatus == 1;
  }

  getAccessToken() {
    return this.globalService.user?.accessToken;
  }

  userLogin(data) {
    return this.makeRequest('post', '/admin-panel/switch-account', data);
  }
}
