import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { HttpClient } from '@angular/common/http';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';

@Component({
  selector: 'app-image-viewer',
  templateUrl: './image-viewer.component.html',
  styleUrls: ['./image-viewer.component.scss']
})
export class ImageViewerComponent implements OnInit {

  imageUrl: string = '';
  imageBlob: Blob;
  imageUrlSafe: SafeUrl;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<ImageViewerComponent>,
    private http: HttpClient,
    private sanitizer: DomSanitizer
  ) { }

  ngOnInit(): void {
    this.imageUrl = this.data.imageUrl;
    this.fetchImage();
  }

  fetchImage() {
    this.http.get(this.imageUrl, { responseType: 'blob' }).subscribe(
      (data: Blob) => {
        this.imageBlob = data;
        const blobUrl = URL.createObjectURL(this.imageBlob);
        this.imageUrlSafe = this.sanitizer.bypassSecurityTrustUrl(blobUrl);
      },
      (error) => {
        console.error('Error fetching image:', error);
      }
    );
  }

}
