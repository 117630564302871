import { Component, OnInit } from '@angular/core';
import { fadeInUpAnimation } from '../../animations/fade-in-up.animation';
import { Router } from '@angular/router';

@Component({
  selector: 'app-page-not-found',
  templateUrl: './page-not-found.component.html',
  styleUrls: ['./page-not-found.component.scss'],
  animations: [fadeInUpAnimation]
})
export class PageNotFoundComponent implements OnInit {

  constructor(private router: Router) { }

  ngOnInit(): void {
  }

  goToDashboard() {
    this.router.navigate(['/']);
  }

}
