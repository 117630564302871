<div fxLayout="row">
  <div fxFlex="100" fxLayoutAlign="center center">
    <div fxLayout="row" fxLayoutAlign="center center">
      <div class="swal2-icon swal2-warning swal2-animate-warning-icon" style="display: flex;margin: 0;">
        <span class="swal2-icon-text pb-2">!</span>
      </div>
      <h3 style="margin-top: 9px; margin-left: 10px;">{{detail.title}}</h3>
    </div>
  </div>
  <div fxLayoutAlign="end center">
    <button *ngIf="buttontype != 'integration'" mat-icon-button mat-dialog-close>
      <mat-icon>close</mat-icon>
    </button>
  </div>

</div>
<br>
<div mat-dialog-content>
  <p [ngClass]="{'loading': showLoading}">{{detail.message}}</p>
</div>

<span *ngIf="adHocSync">
  <mat-checkbox color="primary" [(ngModel)]="createAudit" [ngModelOptions]="{standalone: true}" name="createAudit">
    <small>Would you like to create an audit for this sync?</small>
  </mat-checkbox>
</span>

<div mat-dialog-actions *ngIf="buttontype != 'integration'" align="end">
  <button *ngIf="buttontype == 'ok'" type="button" mat-button color="primary" class="close" aria-label="Close"
    (click)="onClick(true)">Ok</button>
  <div *ngIf="buttontype == 'delete'" align="end">
    <button type="button" mat-button (click)="onClick(false)">CANCEL</button>
    <button type="button" mat-button color="warn" (click)="onClick(true)">CONFIRM</button>

  </div>
</div>